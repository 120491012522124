//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      address: form.address,
      // 	地址
      email: form.email,
      // 	邮箱
      id: form.id,
      //
      name: form.name,
      // 	名称
      phone: form.phone,
      // 手机号码
      remark: form.remark,
      // 	简介
      fileList: [{
        fileUrl: form.fileList[0].fileUrl,
        // 	资源访问地址
        id: form.fileList[0].id,
        //
        ossPath: form.fileList[0].ossPath,
        //	资源存储路径
        sourceId: form.fileList[0].sourceId,
        // 	文件关联业务id
        sourceTag: form.fileList[0].sourceTag //	关联业务类别标签 	从<宣传图、茶室展示、品茗优享>中选值上传
      }]
    };

    let dataForm = form;
    return {
      dataForm,
      stateList,
      // 状态
      imgLoading: false,
      loading: false
    };
  },
  mounted() {
    console.log(this.form, '带过来的');
    // modify==='add'?1:dataForm.state
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
  },
  props: ['form', 'modify'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.ossPath = result.ossUrl;
      }
    },
    save() {
      // console.log(this.dataForm)
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};