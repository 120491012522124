//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let fileArr = [];
import { uploadCommodityDetail, deleteDetailItem } from "../../views/modules/teahouseManage/api";
export default {
  name: "editDetail",
  props: {
    id: {
      type: Number,
      require: true
    },
    tag: {
      require: true
    }
  },
  data() {
    return {
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false
    };
  },
  async created() {
    fileArr = [];
    console.log("id=", this.id, "tag=", this.tag);
    let url;
    if ("商品详情图" === this.tag) {
      url = `/shop/product/get`;
    } else if ("茶艺师详情图" === this.tag) {
      url = `/shop/specialist/get`;
    }
    let [success, data] = await this.$axiosTea({
      url: url,
      method: "get",
      params: {
        id: this.id
      }
    });
    if (data.code === 200) {
      //console.log(data.data.detailsImg);
      this.fileList = data.data.detailsImg.map(item => {
        item.url = item.fileUrl;
        return item;
      });
    } else {
      this.$message.warning("打开错误...");
    }
  },
  methods: {
    async handleRemove(file) {
      console.log("file", file);
      this.fileList.splice(this.fileList.indexOf(file), 1);
      if (file.id) {
        await deleteDetailItem(file.id);
        this.$message({
          message: "删除成功",
          type: "success"
        });
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    changeFileHandle(file) {
      console.log("file", file);
      fileArr.push(file.raw);
      this.fileList = this.fileList.concat(file);
    },
    async uploadHandle() {
      console.log("tag", this.tag);
      console.log("fileArr", fileArr);
      if (fileArr.length > 0) {
        let resp = await uploadCommodityDetail({
          sourceId: this.id,
          tag: this.tag,
          fileArr
        });
        if (resp.code == 200) {
          this.$message({
            message: "上传成功",
            type: "success"
          });
        } else {
          this.$message.error("上传失败");
        }
      }
      this.$emit("changeStatus");
    }
  }
};