//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmail, isMobile } from '@/utils/validate';
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error('密码不能为空'));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error('确认密码不能为空'));
      } else if (this.dataForm.password !== value) {
        callback(new Error('确认密码与密码输入不一致'));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('邮箱格式错误'));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      roleList: [],
      dataForm: {
        id: 0,
        userName: '',
        password: '',
        comfirmPassword: '',
        salt: '',
        email: '',
        mobile: '',
        roleIdList: [],
        status: 1
      },
      dataRule: {
        userName: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        password: [{
          validator: validatePassword,
          trigger: 'blur'
        }],
        comfirmPassword: [{
          validator: validateComfirmPassword,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '邮箱不能为空',
          trigger: 'blur'
        }, {
          validator: validateEmail,
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.$axiosPer({
        url: '/sys/role/select',
        method: 'get'
      }).then(datas => {
        let [success, data] = datas;
        this.roleList = data && data.code === 0 ? data.list : [];
      }).then(() => {
        this.visible = true;
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields();
        });
      }).then(() => {
        if (this.dataForm.id) {
          this.$axiosPer({
            url: `/sys/user/info/${this.dataForm.id}`,
            method: 'get'
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.dataForm.userName = data.user.username;
              this.dataForm.salt = data.user.salt;
              this.dataForm.email = data.user.email;
              this.dataForm.mobile = data.user.mobile;
              this.dataForm.roleIdList = data.user.roleIdList;
              this.dataForm.status = data.user.status;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axiosPer({
            url: `/sys/user/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              userId: this.dataForm.id || undefined,
              username: this.dataForm.userName,
              password: this.dataForm.password,
              salt: this.dataForm.salt,
              email: this.dataForm.email,
              mobile: this.dataForm.mobile,
              status: this.dataForm.status,
              roleIdList: this.dataForm.roleIdList
            }
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};