//
//
//
//

export default {
  data() {
    return {};
  },
  // props: [],
  mounted() {},
  methods: {},
  // watch: {},
  // computed: {},
  components: {}
};