/**
 * UI组件, 统一使用饿了么桌面端组件库(https://github.com/ElemeFE/element）
 */
import Vue from 'vue';
import ElementUI from 'element-ui';
// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'

// // 设置语言
// locale.use(lang)

Vue.use(ElementUI);
Vue.use(ElementUI.Loading.directive);
Vue.prototype.$loading = ElementUI.Loading.service;
Vue.prototype.$msgbox = ElementUI.MessageBox;
Vue.prototype.$alert = ElementUI.MessageBox.alert;
Vue.prototype.$confirm = ElementUI.MessageBox.confirm;
Vue.prototype.$prompt = ElementUI.MessageBox.prompt;
Vue.prototype.$notify = ElementUI.Notification;
Vue.prototype.$message = ElementUI.Message;

// Vue.prototype.$ELEMENT = { size: 'medium' }
Vue.prototype.$ELEMENT = {
  size: 'small'
};

// el-button 按钮的 focus 样式效果去除
window.addEventListener('click', function (e) {
  let target = e.target || null;
  while (true) {
    if (!target) return;
    if (target.nodeName === 'BUTTON') break;
    target = target.parentNode;
  }
  target.blur();
});