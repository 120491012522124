//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
const toolbarOptions = [
// ['link', 'image'],  // 上传图片、上传视频
['bold', 'italic', 'underline', 'strike'],
// 加粗，斜体，下划线，删除线
['blockquote', 'code-block'], [{
  header: 1
}, {
  header: 2
}],
// 几级标题
[{
  list: 'ordered'
}, {
  list: 'bullet'
}],
// 有序列表，无序列表
[{
  script: 'sub'
}, {
  script: 'super'
}],
// 下角标，上角标
[{
  indent: '-1'
}, {
  indent: '+1'
}],
// 缩进
[{
  direction: 'rtl'
}],
// 文字输入方向
[{
  size: ['small', false, 'large', 'huge']
}],
// 字体大小
[{
  header: [1, 2, 3, 4, 5, 6, false]
}],
// 标题
// [{ 'color': [] }, { 'background': [] }],          // 颜色选择
// [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }], // 字体
// [{ 'align': [] }], // 居中
['clean'] // 清除样式,
];

export default {
  data() {
    let form = this.form || {
      id: undefined,
      // 修改时的主键id
      homeImg: undefined,
      // homeImg
      category: undefined,
      // 类型
      detailsText: undefined,
      //
      coverImg: undefined,
      // 封面图
      detailsImg: undefined,
      // 详情图
      name: undefined,
      // 公司简称
      fullName: undefined,
      // 公司全称
      sortNo: undefined,
      linkUrl: undefined
    };
    let dataForm = form;
    return {
      dataForm,
      newTime: null,
      imgLoading: false,
      loading: false,
      name: 'register-modules-example',
      content: '',
      editorOption: {
        placeholder: '请输入案例详情',
        theme: 'snow',
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector('.avatar-uploader input').click();
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
      uploadUrl: `https://console.irobotop.com/apis/upload-service/upload/file` // 服务器上传地址
    };
  },

  mounted() {
    // console.log(this.dataForm)
    this.content = this.dataForm.detailsText;
  },
  props: ['form'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.coverImg = result.ossUrl;
      }
    },
    // 失去焦点
    onEditorBlur(editor) {},
    // 获得焦点
    onEditorFocus(editor) {},
    // 开始
    onEditorReady(editor) {},
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
    },
    beforeUpload(file) {},
    // 上传图片成功
    uploadSuccess(res) {
      // console.log(res, '000')
      this.imgLoading = false;
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // console.log(res, '图片')
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // console.log(length, 'length---====', res.ossUrl)
        // 插入图片，res为服务器返回的图片链接地址
        // let formatImg = `![](${res.ossUrl})`
        // quill.insert(formatImg)
        quill.insertEmbed(length, 'image', res.ossUrl);
        // 调整光标到最后
        quill.setSelection(length + 5);
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败！');
      }
    },
    // homeImg
    upLoadHomeData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.homeImg = result.ossUrl;
      }
    },
    // detailsImg 详情图
    upLoadDetailsData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.detailsImg = result.ossUrl;
      }
    },
    // coverImg 封面图
    upLoadCoverData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.coverImg = result.ossUrl;
      }
    },
    save() {
      const dom = document.createElement('div');
      dom.innerHTML = this.content;
      // console.log(this.content)
      this.dataForm.detailsText = this.content;
      this.$emit('success', this.dataForm);
    }
  },
  components: {
    quillEditor
  }
};