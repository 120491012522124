//
//
//
//
//
//

export default {
  name: 'BackImg',
  props: {
    src: {
      type: String,
      default: undefined
    }
  }
};