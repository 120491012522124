import Cookies from 'js-cookie';
import router from '@/router';
import store from '@/store';
import axios from '@/utils/axios';

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? Math.random() * 16 | 0 : 'r&0x3' | '0x8').toString(16);
  });
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  // console.log("树的获取", data);
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = [];
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1;
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]['children'].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  Cookies.remove('token');
  store.commit('resetStore');
  router.options.isAddDynamicMenuRoutes = false;
}

/**
 * 时间戳转易读时间格式
 * @param {number} timestamp 时间戳
 * @param {String} type 类型： number / zh
*/
export function getDate(timestamp, type = 'number') {
  let date;
  if (timestamp && timestamp - 0 && !isNaN(timestamp - 0)) date = new Date(timestamp - 0);else date = new Date();
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var h = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  // 时分秒都需要加零
  h = h < 10 ? '0' + h : h;
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  if (type === 'number') {
    m = m < 10 ? '0' + m : m;
    d = d < 10 ? '0' + d : d;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
  } else if (type === 'zh') {
    return y + '年' + m + '月' + d + '日' + ' ' + h + ':' + minute + ':' + second;
  }
}

/**
 * 时间间隔转为易读的时间格式(天)
 * @param {number} timespace 时间间隔
 * @param {array | undefined} 天/时/分/秒 后面的分隔符
 */
export function getDateSpace(timespace, [d, h, m, s] = [':', ':', ':', '']) {
  timespace = timespace - 0;
  if (!timespace || typeof timespace !== 'number' || timespace <= 0) return `00${d}00${h}00${m}00${s}`;
  let oneS = 1000; // 1s
  let oneM = oneS * 60; // 1分钟
  let oneH = oneM * 60; // 1小时
  let oneD = oneH * 24; // 1天
  let temp = timespace;
  let day = Math.floor(temp / oneD);
  temp = temp % oneD;
  let hour = Math.floor(temp / oneH);
  temp = temp % oneH;
  let minute = Math.floor(temp / oneM);
  temp = temp % oneM;
  let second = Math.floor(temp / oneS);
  day = day < 10 ? '0' + day : day;
  hour = hour < 10 ? '0' + hour : hour;
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  return `${day}${d}${hour}${h}${minute}${m}${second}${s}`;
}

/**
 * select 使用的 list，转为 value -> label 的 map
 * @param {Array} list list
 * @param {value} list 作为 map 的 key 的字段名
 * @param {label} list 作为 map 的 值的字段名
 */
export function list2map(list = [], {
  value = 'value',
  label = 'label'
} = {}) {
  let map = {};
  list.forEach(item => {
    map[item[value]] = item[label];
  });
  return map;
}

// 获取帖子对象
export function getArticleArr(jsonStr) {
  let array = JSON.parse(jsonStr);
  let resultArr = array.map(itemStr => {
    itemStr = `${itemStr}`;
    let regResult = itemStr.match(/^([^-]*)-([\s\S]*)$/);
    let id = '',
      title = '';
    if (regResult) {
      id = regResult[1];
      title = regResult[2];
    } else {
      id = itemStr;
    }
    return {
      id,
      title
    };
  });
  return resultArr;
}

// 传入文件地址进行下载
export function downFileFromUrl(fileUrl, fileName) {
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = fileUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // 下载完成移除元素
}

// 传入流数据，下载为文件
export function downBlob({
  data,
  type,
  fileName
}) {
  let blob = new Blob([data], {
    type
  });
  if ('download' in document.createElement('a')) {
    // 不是IE浏览器
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // 下载完成移除元素
    window.URL.revokeObjectURL(url); // 释放掉blob对象
  } else {
    // IE 10+
    window.navigator.msSaveBlob(blob, fileName);
  }
}
// 下载模板文件，传入文件名
export const downTemp = async fileName => {
  var _response$data;
  let result = await axios({
    url: `/cms-boss/file/downLoadTemp`,
    method: 'get',
    tip: false,
    responseType: 'blob',
    params: {
      fileName
    }
  });
  let response = result[2];
  if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.size) {
    var _response$headers, _response$headers$con, _response$headers$con2;
    // 下载
    downBlob({
      data: response.data,
      contentType: response.headers['content-type'],
      fileName: decodeURIComponent(response === null || response === void 0 ? void 0 : (_response$headers = response.headers) === null || _response$headers === void 0 ? void 0 : (_response$headers$con = _response$headers['content-disposition']) === null || _response$headers$con === void 0 ? void 0 : (_response$headers$con2 = _response$headers$con.match(/filename=([^;]*);?/)) === null || _response$headers$con2 === void 0 ? void 0 : _response$headers$con2[1]) || fileName
    });
  }
};

/**
 * 从带有 HTML 标签的字符串中，提取出纯 text 内容
 * @param {String} HTML 
 * @returns {String}
 */
export function HTML2Text(HTML = '') {
  return HTML.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
}

/**
 * 回显时的字符串调整，比如把 &nbsp; 转为空格
 * @param {String} text 等待转的字符串
 */
export function strFormat(text = '') {
  text = (text || '').replace(/&nbsp;/ig, ' ');
  return text;
}

/**
 * 保留n位小数,直接舍去小数后的数，比如 toFixed(1.888, 2) => 1.88
 * @param {string|number} data 需要由科学计数法转为字符串格式的小数
 * @param {Boolean} keep0 不足位数的小数，是否自动补零
 */
export function toFixed(numStr, decimal, keep0 = false) {
  if (isNaN(numStr)) return 0;
  let numArr = (numStr || '').toString().split('.');
  let intStr = numArr[0] || ''; // 整数部位
  let decimalStr = numArr[1] || ''; // 小数部位

  decimalStr = decimalStr.substr(0, decimal);
  if (keep0) {
    // 保持0
    while (decimalStr.length < decimal) decimalStr += '0';
    return intStr + '.' + decimalStr;
  }
  // 不保持0
  else return Number(intStr + '.' + decimalStr);
}

/**
 * 加载编辑器
 */
export function loadEdit() {
  return new Promise(resolve => {
    window.$LAB.script('/plugins/wangEditor.min.js').wait(() => {
      resolve([!!window.wangEditor, window.wangEditor]);
    });
  });
}