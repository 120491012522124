//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      phone: form.phone,
      //
      nickName: form.nickName,
      //	名称
      gold: form.gold,
      // 	金币-通过活跃使用获取
      email: form.email,
      // 会员邮箱
      userId: form.userId,
      integral: form.integral,
      // 积分-Rmb充值
      remark: form.remark,
      // 备注
      state: form.state,
      // 状态
      wechatId: form.wechatId // 微信号
    };

    let dataForm = form;
    return {
      dataForm,
      stateList,
      // 状态
      imgLoading: false,
      loading: false
    };
  },
  mounted() {},
  props: ['form'],
  methods: {
    save() {
      // console.log(this.dataForm)
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};