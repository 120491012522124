//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // computed: {
  //   navbarLayoutType: {
  //     get() { return this.$store.state.common.navbarLayoutType },
  //     set(val) { this.$store.commit('common/updateNavbarLayoutType', val) }
  //   },
  //   sidebarLayoutSkin: {
  //     get() { return this.$store.state.common.sidebarLayoutSkin },
  //     set(val) { this.$store.commit('common/updateSidebarLayoutSkin', val) }
  //   }
  // }
};