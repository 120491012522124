//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddNews from '@/components/szrs/AddNews';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  pageNumber: 1,
  // 页面大小
  pageSize: 10 // 页码
};

export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      newsShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosSzrs({
        url: `/journalism/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // console.log(data);
      if (data.records.length > 0) {
        // console.log(data,'数据列表');
        this.list = data.records || [];
        this.total = data.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changePageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      // console.log("删除的", row);
      // return;
      this.$confirm(`确认删除：[${row.title}]相关新闻嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosSzrs.get(`/journalism/delete/${row.id}`);
        if (data.code === 200) {
          this.$message.success('删除成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    },
    // 新增
    addNews() {
      // console.log("新增的");
      this.isEdit = 'add';
      this.editForm = null;
      this.newsShow = true;
    },
    // 查看详情/编辑
    async edit(row = {}) {
      this.isEdit = 'edit';
      let that = this;
      let [success, data] = await this.$axiosSzrs({
        url: `/journalism/get`,
        method: 'get',
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        // console.log(data,'数据列表');
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.newsShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      let that = this;
      var dataForm;
      if (this.isEdit === 'add') {
        dataForm = {
          time: form.time,
          // 新闻日期
          title: form.title,
          // 新闻名称
          coverImg: form.coverImg,
          // 展示图
          content: form.content,
          // 内容列表 包括段落和图片
          author: form.author,
          // 新闻作者
          source: form.source // 新闻来源
        };
      } else if (this.isEdit === 'edit') {
        dataForm = {
          id: form.id,
          // 修改时的主键id
          time: form.time,
          // 新闻日期
          title: form.title,
          // 新闻名称
          coverImg: form.coverImg,
          // 展示图
          content: form.content,
          // 内容列表 包括段落和图片
          author: form.author,
          // 新闻作者
          source: form.source // 新闻来源
        };
      }
      // console.log('要保存的数据：：', dataForm)
      this.loading = true;
      let [success, data] = await this.$axiosSzrs({
        url: `/journalism/save`,
        method: 'post',
        data: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.newsShow = false;
        that.$message.success('操作成功');
        that.getList(1);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    AddNews
  }
};