//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCardList, getRightsHandle, postCardListMsg } from "./api";
export default {
  created() {
    this.getCardListHandle();
    this.getRights();
  },
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {},
      formLabelWidth: "120px",
      checkboxGroup: [],
      inputValue: [],
      rightsArr: [],
      disabled: true
    };
  },
  methods: {
    async getCardListHandle() {
      let {
        data
      } = await getCardList();
      this.tableData = data;
      console.log("data", data);
    },
    changeValueHandle(e) {
      console.log("changeValueHandle", e);
    },
    handleClick(row) {
      this.disabled = false;
      this.formatterData(row);
      this.dialogFormVisible = true;
    },
    deteteRights() {},
    async getRights() {
      //初始化加载全部权益
      let resp = await getRightsHandle();
      console.log("getRights", resp);
      this.rightsArr = resp.data;
      this.rightsArr.forEach(item => {
        this.inputValue.push({
          id: item.id,
          value: ""
        });
      });
    },
    viewClick(row) {
      this.disabled = true;
      this.formatterData(row);
      this.dialogFormVisible = true;
    },
    formatterData(row) {
      //初始化默认值
      this.form = row;
      let choiceIdArr = [];
      let choiceValueArr = [];
      this.checkboxGroup = [];
      if (this.form.equityIds) {
        this.form.equityIds.split(",").forEach(item => {
          let resp = item.split("#");
          choiceIdArr.push(resp[0]);
          choiceValueArr.push(resp[1]);
          this.checkboxGroup.push(Number(resp[0]));
        });
      }
      console.log(this.checkboxGroup);
      this.inputValue.forEach((item, i) => {
        choiceIdArr.forEach((num, index) => {
          if (item.id == num) {
            this.inputValue[i].value = choiceValueArr[index];
          }
        });
      });
    },
    async confirmEditHandle() {
      //提交
      let valueArr = [];
      console.log("inputValue", this.inputValue);
      try {
        this.inputValue.forEach((item, index) => {
          let i = this.checkboxGroup.indexOf(Number(item.id));
          if (i != -1) {
            console.log("value", item.value, item.value, item.value == 0);
            if (!item.value || item.value == 0) {
              this.$message({
                type: "warning",
                message: "选中的权益次数不能为空"
              });
              throw Error("请输入次数");
            } else {
              valueArr.push(`${item.id}#${item.value}`);
            }
          }
        });
      } catch (error) {
        console.log("error", error);
        return;
      }
      valueArr = valueArr.join(",");
      console.log("valueArr", valueArr);
      let resp = await postCardListMsg({
        id: this.form.id,
        integral: this.form.integral,
        name: this.form.name,
        equityIds: valueArr
      });
      if (resp.code == 200) {
        this.$message({
          type: "success",
          message: "编辑成功"
        });
        this.getCardListHandle();
      } else {
        this.$message({
          type: "fail",
          message: resp.msg
        });
      }
      this.dialogFormVisible = false;
    }
  }
};