//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { clearLoginInfo } from '@/utils';
export default {
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (this.dataForm.newPassword !== value) {
        callback(new Error('确认密码与新密码不一致'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      dataRule: {
        password: [{
          required: true,
          message: '原密码不能为空',
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          message: '新密码不能为空',
          trigger: 'blur'
        }],
        confirmPassword: [{
          required: true,
          message: '确认密码不能为空',
          trigger: 'blur'
        }, {
          validator: validateConfirmPassword,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    userName: {
      get() {
        return this.$store.state.auth.userInfo.username;
      }
    },
    userId: {
      get() {
        return this.$store.state.auth.userInfo.userId;
      }
    }
  },
  methods: {
    // 初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
    },
    // // post
    // /irobotweb/sys/user/update/password?userId=124&password=123456
    // 表单提交
    dataFormSubmit() {
      // console.log(this.$store.state.auth.userInfo);
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axiosPer({
            url: '/irobotweb/sys/user/update/password',
            method: 'post',
            params: {
              userId: this.userId,
              password: this.dataForm.newPassword
            }
          }).then(([success, msg]) => {
            // let [datas] = data;
            // console.log(success,msg.msg);

            if (success) {
              this.$message.success('操作成功！');
              this.visible = false;
              this.$nextTick(() => {
                clearLoginInfo();
                this.$router.replace({
                  name: 'login'
                });
              });
            } else {
              this.$message.error(msg.msg);
            }
          });
        }
      });
    }
  }
};