//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      id: form.id,
      // 修改时的主键id
      state: form.state,
      //	状态：1-正常(新增时默认);0-冻结
      name: form.name,
      //	名称
      remark: form.remark,
      // 备注
      area: form.area,
      // 农场面积情况
      plantingNum: form.plantingNum,
      // 可种植数总量
      userId: form.userId
    };
    let dataForm = form;
    return {
      dataForm,
      stateList,
      // 状态
      userList: [],
      //用户列表
      imgLoading: false,
      loading: false
    };
  },
  mounted() {
    // modify==='add'?1:dataForm.state
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
    this.getUserList();
  },
  props: ['form', 'modify'],
  methods: {
    // 获取列表
    async getUserList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/wx/mini/findList`,
        method: 'get',
        params: {
          pageSize: 999
        }
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data,'用户管理');
        // return
        let datalist = data.data;
        this.userList = datalist.rows || [];
      } else {
        this.userList = [];
      }
    },
    save() {
      // console.log(this.dataForm)
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};