<template>
  <main class="site-content" :class="{ 'site-content--tabs': $route.meta.isTab }">
    <div v-if="breadcrumbList && breadcrumbList.length" class="breadcrumbBox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="item in breadcrumbList"
          :key="item.menuId"
          :to="item.urlName ? {name: item.urlName} : undefined"
        >{{item.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 主入口标签页 s -->
    <el-card class="site-content-card" :body-style="siteContentViewHeight">
      <iframe
        v-if="$route.meta.iframeUrl"
        :src="$route.meta.iframeUrl"
        width="100%" height="100%" frameborder="0" scrolling="yes">
      </iframe>
      <router-view @hook:created="pageCreated" v-else/>
    </el-card>
  </main>
  
</template>

<script>
import { isURL } from '@/utils/validate'
export default {
  inject: ['getBreadFromPath'],
  data() {
    return {
      breadcrumbList: [],
      pageSetedBread: false, // 用来标记，页面的 created 是否处理过 breadcrumbList
    }
  },
  methods: {
    // 子页面 created 了
    pageCreated() {
      this.breadcrumbList = []

      if (this.$route.meta.breadcrumbList) {
        this.breadcrumbList = this.$route.meta.breadcrumbList
      } else if (!this.pageSetedBread && this.$route.meta.isTab) {
        // 子页面没有处理 breadcrumbList，则此页面自己处理
        this.breadcrumbList = this.getBreadFromPath(this.$route.path, true)
      }

      // 重置标记
      this.pageSetedBread = false
    },
  },
  computed: {
    documentClientHeight: {
      get() { return this.$store.state.common.documentClientHeight }
    },
    siteContentViewHeight() {
      var height = this.documentClientHeight - 50 - 30 - 2 - 5
      if (this.$route.meta.isTab) {
        height -= 40
        return isURL(this.$route.meta.iframeUrl) ? { height: height + 'px' } : { minHeight: height + 'px' }
      }
      return { minHeight: height + 'px' }
    }
  },
}
</script>
<style lang="scss">
.site-content {
  $breadcrumbBoxHeight: 50px;
  &.site-content--tabs {
    padding: 0 0 15px;
    .site-content-card {
      margin-top: #{$breadcrumbBoxHeight + 10};
    }
  }
  .breadcrumbBox {
    background-color: var(--weight_10);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    height: $breadcrumbBoxHeight;
    display: flex;
    align-items: center;
    padding-left: 15px;
    position: fixed;
    top: 50px;
    left: 230px;
    width: 100vw;
    z-index: 1003;
  }
  .site-content-card {
    margin: 0 15px;
  }
}
</style>
