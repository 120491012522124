//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 预约状态：预约中(0), 已完成(1), 取消预约(9)
const BookingStateList = [{
  value: 0,
  label: '预约中'
}, {
  value: 1,
  label: '已完成'
}, {
  value: 9,
  label: ' 取消预约'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      id: form.id,
      // 修改时的主键id
      ossPath: form.ossPath,
      // 地址类型-手填吧
      state: form.state,
      //	状态：1-正常(新增时默认);0-冻结
      name: form.name,
      //	名称
      remark: form.remark // 备注
    };

    let dataForm = form;
    return {
      dataForm,
      stateList,
      // 状态
      imgLoading: false,
      loading: false,
      BookingStateList //预约状态：预约中(0), 已完成(1), 取消预约(9)
    };
  },

  mounted() {
    console.log(this.form, '带过来的');
    // modify==='add'?1:dataForm.state
  },

  props: ['form'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.ossPath = result.ossUrl;
      }
    },
    save() {
      // console.log(this.dataForm)
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};