//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        paramKey: '',
        paramValue: '',
        remark: ''
      },
      dataRule: {
        paramKey: [{
          required: true,
          message: '参数名不能为空',
          trigger: 'blur'
        }],
        paramValue: [{
          required: true,
          message: '参数值不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(async () => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          let [success, data] = await this.$axiosPer({
            url: `/sys/config/info/${this.dataForm.id}`,
            method: 'get',
            params: this.$http.adornParams()
          });
          if (data && data.code === 0) {
            this.dataForm.paramKey = data.config.paramKey;
            this.dataForm.paramValue = data.config.paramValue;
            this.dataForm.remark = data.config.remark;
          }
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axiosPer({
            url: `/sys/config/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              'id': this.dataForm.id || undefined,
              'paramKey': this.dataForm.paramKey,
              'paramValue': this.dataForm.paramValue,
              'remark': this.dataForm.remark
            }
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};