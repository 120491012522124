import Vue from 'vue';
import ImgMax from "@/components/ImgMax";
import BackImg from "@/components/BackImg";
import Upload from "@/components/Upload";
import TeaShopUpload from "@/components/TeaShopUpload";
import UploadExcel from "@/components/UploadExcel";
import MySelect from "@/components/MySelect";
Vue.component(ImgMax.name, ImgMax); // 限制的最大长宽的图片
Vue.component(BackImg.name, BackImg); // 图片做背景图的盒子
Vue.component(Upload.name, Upload); // 上传图片功能
Vue.component(TeaShopUpload.name, TeaShopUpload); // 茶馆店铺上传图片
Vue.component(UploadExcel.name, UploadExcel); // 上传Excel
Vue.component(MySelect.name, MySelect); // 自己封装的可以有选择全部功能的