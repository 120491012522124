export default {
  namespaced: true,
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 侧边栏, 折叠状态
    sidebarFold: false
  },
  mutations: {
    updateDocumentClientHeight(state, height) {
      state.documentClientHeight = height;
    },
    updateSidebarFold(state, fold) {
      state.sidebarFold = fold;
    }
  }
};