//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MySelect",
  data() {
    return {
      now: this.defaultValue !== undefined ? this.defaultValue : ""
    };
  },
  props: {
    loading: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    placeholder: String,
    value: {
      type: String,
      default: "value"
    },
    label: {
      type: String,
      default: "label"
    },
    list: {
      required: true,
      type: Array,
      default: []
    },
    allValue: {
      // all 的那个 value 值
      type: String,
      default: "all"
    },
    defaultValue: String | Number
  },
  methods: {
    change(newVal) {
      // 如果选中的 all
      if (newVal === this.allValue) this.$emit("change", {});else this.$emit("change", this.list.find(item => item[this.value] === newVal) || {});
    }
  }
};