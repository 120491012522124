//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HarvestAdd from '@/components/smartFarm/HarvestAdd';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  farmId: undefined,
  // 农场id
  state: undefined,
  // 状态
  productType: undefined,
  // 农产品类型
  pageNumber: 1,
  // 页面大小
  pageSize: 10 // 页码
};

const typeList = [{
  value: 3,
  label: '收获物'
}, {
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      typeList,
      // 类型list
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      manageHShow: false,
      // 添加 编辑的弹框是否显示
      farmList: [],
      // 农场列表
      chooseF: null,
      // 选择的农场
      allFram: {} // 用于展示的农场id和名字对应们
    };
  },

  mounted() {
    this.getFarmList();
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ''].includes(this.queryInfo.productType)) {
        this.queryInfo.productType = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.state)) {
        this.queryInfo.state = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.farmId)) {
        this.queryInfo.farmId = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      if (this.chooseF) {
        this.queryInfo.farmId = this.chooseF.id;
      }
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/harvest/findList`,
        method: 'get',
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // farmList
    // 获取所有的农场
    async getFarmList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/findList`,
        method: 'get',
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        let allFrams = data.data.rows;
        let keyArray = {};
        allFrams.forEach(item => {
          keyArray[item.id] = item.name;
        });
        this.allFram = keyArray;
        // return
        let datalist = data.data;
        this.farmList = datalist.rows || [];
      } else {
        this.farmList = [];
      }
    },
    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      this.$confirm(`确认删除农产品嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosWi({
          url: `/farm/harvest/delete`,
          method: 'get',
          params: {
            id: row.id
          }
        });
        if (data.code === 200) {
          this.$message.success('删除成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    },
    // 新增
    HarvestAdd() {
      this.isEdit = 'add';
      this.editForm = {};
      this.manageHShow = true;
    },
    // 查看详情/编辑
    async edit(row = {}) {
      this.isEdit = 'edit';
      let that = this;
      let [success, data] = await this.$axiosWi({
        url: `/farm/harvest/get`,
        method: 'get',
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        // console.log(data.data, '农产品详情')
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.manageHShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url;
      let that = this;
      var dataForm;
      if (this.isEdit === 'add') {
        dataForm = {
          farmId: form.farmId,
          productType: form.productType,
          //1-蔬菜、2-家禽、3-收获物(如鸡蛋)
          state: form.state,
          //	状态：1-正常(新增时默认);0-冻结
          remark: form.remark,
          // 备注
          number: form.number,
          // 数量
          farmProductId: form.farmProductId //
        };
      } else if (this.isEdit === 'edit') {
        dataForm = {
          farmId: form.farmId,
          id: form.id,
          // 修改时的主键id
          productType: form.productType,
          //1-蔬菜、2-家禽、3-收获物(如鸡蛋)
          state: form.state,
          //	状态：1-正常(新增时默认);0-冻结
          remark: form.remark,
          // 备注
          number: form.number,
          // 数量
          farmProductId: form.farmProductId //
        };
      }

      this.loading = true;
      let token = this.$cookie.get('token');
      let [success, data] = await this.$axiosWi({
        url: '/farm/harvest/save',
        method: 'post',
        header: {
          token
        },
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.manageHShow = false;
        that.$message.success('操作成功');
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    HarvestAdd
  }
};