//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const tagList = [{
  value: '宣传图',
  label: '宣传图'
}, {
  value: '茶室展示',
  label: '茶室展示'
}, {
  value: '品茗优享',
  label: '品茗优享'
}];
export default {
  data() {
    let form = this.form || {
      shopId: form.shopId,
      // 	店铺ID
      sourceTag: form.sourceTag,
      // 		标签从<宣传图、茶室展示、品茗优享>中选值上传
      file: form.file //
    };

    let dataForm = form;
    return {
      dataForm,
      tagList,
      // 标签
      loading: false,
      imgLoading: false
    };
  },
  mounted() {},
  props: ['form'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      // console.log('拿到的图片文件：：：', result)
      this.imgLoading = false;
      this.dataForm.file = result;
      // if (result) {
      //   this.dataForm.ossPath = result.ossUrl
      // }
    },

    save() {
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};