//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavbarUpdatePassword from "@/components/main/NavbarUpdatePassword";
import { clearLoginInfo } from "@/utils";
import { webTitle } from "@/utils/global.config";
import { ENV_CONFIG } from '@/utils/global.config';
import SockJS from "sockjs-client";
import Stomp from "stompjs";
//import { Notification } from 'element-ui';
export default {
  name: "NavBar",
  data() {
    return {
      updatePassowrdVisible: false,
      webTitle,
      audio: {
        //消息通知
        src: require("../../assets/audio/Yahaha_vv.mp3")
      }
    };
  },
  components: {
    NavbarUpdatePassword
  },
  computed: {
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
      set(val) {
        this.$store.commit("common/updateSidebarFold", val);
      }
    },
    userName: {
      get() {
        return this.$store.state.auth.userInfo.name;
      }
    }
  },
  mounted() {
    this.connect();
  },
  methods: {
    // 修改密码
    updatePasswordHandle() {
      this.updatePassowrdVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init();
      });
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let token = this.$cookie.get("token");
        this.$axiosPer({
          url: "/sys/logout",
          method: "post",
          header: {
            token
          }
        }).then(([success, data]) => {
          if (data.code === 0) {
            clearLoginInfo();
            this.$router.push({
              name: "login"
            });
          }
        });
      }).catch(() => {});
    },
    //创建socket连接
    connect() {
      let that = this;
      console.log("connect");
      //var socket = new SockJS("http://localhost:6066/ws");
      //var socket = new SockJS("https://console.irobotop.com/teaApp/ws");
      var socket = new SockJS(ENV_CONFIG.tea + "/ws");
      let stompClient = Stomp.over(socket);
      stompClient.connect({}, function (frame) {
        console.log('Connected:' + frame);
        var sum = 0;
        var interval;
        stompClient.subscribe("/topic/message", function (response) {
          var body = JSON.parse(response.body);
          console.log(body);

          //启动播放
          sum = 0;
          interval = setInterval(func, 3000);
          let msg = '预约人：' + body.name + "<br/>";
          msg += '预约时间：' + body.fullTime;
          //消息弹框
          that.$notify({
            title: '预约通知',
            message: msg,
            position: 'bottom-right',
            duration: 0,
            offset: 40,
            dangerouslyUseHTMLString: true,
            onClick: function () {
              console.log("to bookingManage");
              that.$router.push('/teahouseManage/bookingManage');
            }
          });
        });
        function func() {
          if (sum < 6) {
            //播放
            that.$refs.audioTip.play();
            sum++;
          } else {
            clearInterval(interval); //停止 
          }
        } //定时任务
      });
    }
  }
};