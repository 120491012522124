//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserEditPage from '@/components/smartFarm/UserEditPage';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  pageNumber: 1,
  // 页面大小
  pageSize: 10 // 页码
};

const typeList = [{
  value: 3,
  label: '收获物'
}, {
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      typeList,
      // 类型list
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      userEditPageShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ''].includes(this.queryInfo.state)) {
        this.queryInfo.state = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.name)) {
        this.queryInfo.name = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.type)) {
        this.queryInfo.type = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/wx/mini/findList`,
        method: 'get',
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '拿到的用户数据')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    // remove(row) {
    //   this.$confirm(`确认删除{${row.name}}嘛？`)
    //     .then(async () => {
    //       this.$set(row, 'removing', true)
    //       let [success, data] = await this.$axiosWi({
    //         url: `/farm/product/delete`,
    //         method: 'get',
    //         params: { id: row.id },
    //       })
    //       if (data.code === 200) {
    //         this.$message.success('删除成功！')
    //         this.getList(1)
    //       } else {
    //         this.$set(row, 'removing', false)
    //       }
    //     })
    //     .catch((_) => _)
    // },
    // 新增
    UserEditPage() {
      this.isEdit = 'add';
      this.editForm = {};
      this.userEditPageShow = true;
    },
    // 查看详情/编辑
    async edit(row = {}) {
      this.isEdit = 'edit';
      let that = this;
      let [success, data] = await this.$axiosWi({
        url: `/wx/mini/get`,
        method: 'get',
        params: {
          openid: row.openid
        }
      });
      if (data.code == 200) {
        // console.log(data.data, '用户信息详情')
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.userEditPageShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url;
      let that = this;
      var dataForm;
      if (this.isEdit === 'add') {
        dataForm = {
          address: form.address,
          //住址
          avatarUrl: form.avatarUrl,
          //	头像Url
          nickName: form.nickName,
          //	名称
          // company: form.company, // 公司名称
          openid: form.openid,
          email: form.email,
          // 邮箱
          phone: form.phone // 手机号
        };
      } else if (this.isEdit === 'edit') {
        dataForm = {
          userId: form.userId,
          // 修改时的主键id
          address: form.address,
          //住址
          avatarUrl: form.avatarUrl,
          //	头像Url
          nickName: form.nickName,
          //	名称
          // company: form.company, // 公司名称
          openid: form.openid,
          email: form.email,
          // 邮箱
          phone: form.phone // 手机号
        };
      }

      this.loading = true;
      let token = this.$cookie.get('token');
      let [success, data] = await this.$axiosWi({
        url: '/wx/mini/save',
        method: 'post',
        header: {
          token
        },
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.userEditPageShow = false;
        that.$message.success('操作成功');
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    UserEditPage
  }
};