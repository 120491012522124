// 登录、权限系统的接口服务封装
// import Vue from 'vue'
import axios from 'axios';
import Cookies from 'js-cookie';
import { Message } from 'element-ui';
import router from '@/router';
import { ENV_CONFIG } from '@/utils/global.config';
import merge from 'lodash/merge';
// import store from '@/store'
import { clearLoginInfo } from '@/utils';
const service = axios.create({
  // timeout: 1000 * 60,
  baseURL: ENV_CONFIG.per,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

/**
 * 请求拦截
 */
service.interceptors.request.use(config => {
  // const {
  // } = config

  if (config.method.toUpperCase() === 'GET') {
    config.params = merge({
      t: Date.now()
    }, config.params);
  }
  config.headers['token'] = Cookies.get('token'); // 请求头带上token
  // console.log('带过去的token', Cookies.get('token'));
  return config;
}, error => {
  return Promise.reject(error);
});

/**
 * 响应拦截
 * 发起请求时，可在 url、method、data 的同级传入参数，用来配置得到响应后的处理
 */
service.interceptors.response.use(async response => {
  return await getResponse(response);
}, async err => {
  var _err$message, _err$message2;
  // 打印错误
  console.error(err);
  if (err.response) return await getResponse(err.response);

  // 超时
  if (err !== null && err !== void 0 && (_err$message = err.message) !== null && _err$message !== void 0 && _err$message.includes('timeout')) {
    Message.error('服务器连接失败'); // 请求超时
  } else if (err !== null && err !== void 0 && (_err$message2 = err.message) !== null && _err$message2 !== void 0 && _err$message2.includes('Network Error')) {
    // 网络错误
    Message.error('网络连接失败'); // 网络错误
  } else {
    Message.error('服务出错，请稍后再试！');
  }
  return [false, null, err];
});
async function getResponse(response) {
  var _response$data, _response$data2;
  if ((response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.code) === 401) {
    // 401, token失效
    clearLoginInfo();
    router.push({
      name: 'login'
    });
  }
  return [(response === null || response === void 0 ? void 0 : (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : _response$data2.code) === 200, response.data, response];
}
export default service;