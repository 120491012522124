//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MsgTrendsAdd from '@/components/smartFarm/MsgTrendsAdd';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  type: 0,
  //动态类型：1-个人 0-官方
  pageNumber: 1,
  // 页面大小
  pageSize: 10 // 页码
};

const dynamicAttributionList = [{
  value: 0,
  label: '官方动态'
}, {
  value: 1,
  label: '个人动态'
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      dynamicAttributionList,
      // 动态类型list
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      msgTrendsShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ''].includes(this.queryInfo.type)) {
        this.queryInfo.type = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/dynamic/findList`,
        method: 'get',
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data,'数据列表');
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      // console.log("删除的", row);
      // return;
      this.$confirm(`确认删除该动态嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosWi({
          url: `/dynamic/delete`,
          method: 'get',
          params: {
            id: row.id
          }
        });
        // console.log(data,'删除');
        if (data.code === 200) {
          this.$message.success('删除成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    },
    // 新增
    MsgTrendsAdd() {
      // console.log("新增的");
      this.isEdit = 'add';
      this.editForm = {};
      this.msgTrendsShow = true;
    },
    // 查看详情/编辑
    async edit(row = {}) {
      this.isEdit = 'edit';
      let that = this;
      let [success, data] = await this.$axiosWi({
        url: `/dynamic/get`,
        method: 'get',
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        // console.log(data.data, '动态消息详情')
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.msgTrendsShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url;
      let that = this;
      var dataForm;
      if (this.isEdit === 'add') {
        dataForm = {
          content: form.content,
          // 动态内容
          coverImg: form.coverImg // 封面图片地址
          // type: form.type, // 动态类型：1-个人 0-官方
        };
      } else if (this.isEdit === 'edit') {
        dataForm = {
          id: form.id,
          // 修改时的主键id
          content: form.content,
          // 动态内容
          coverImg: form.coverImg // 封面图片地址
          // type: form.type, // 动态类型：1-个人 0-官方
        };
      }

      this.loading = true;
      let token = this.$cookie.get('token');
      let [success, data] = await this.$axiosWi({
        url: '/dynamic/save',
        method: 'post',
        header: {
          token
        },
        params: dataForm
      });
      this.loading = false;
      // console.log(data,'添加或者编辑');
      if (data.code == 200) {
        that.msgTrendsShow = false;
        that.$message.success('操作成功');
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    MsgTrendsAdd
  }
};