//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  name: "chayishi",
  data() {
    return {
      stateList,
      // 状态
      imgLoading: false,
      loading: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      dataForm: {
        id: null,
        // 修改时的主键id
        name: "",
        //	名称
        grade: null,
        orderNum: null,
        //	编号
        phone: "",
        //	手机号
        speciality: "" //	特长
      }
    };
  },

  mounted() {
    if (this.form) {
      console.log(this.form);
      for (let key in this.dataForm) {
        this.dataForm[key] = this.form[key];
      }
      if (this.form.fileUrl) {
        this.fileList = [{
          url: this.form.fileUrl
        }];
      }
    }
    console.log(this.dataForm);
  },
  props: ["form", "modify"],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.ossPath = result.ossUrl;
      }
    },
    save() {
      this.$emit("success", this.dataForm);
    },
    handleRemove(file) {
      this.fileList = [];
      this.dataForm.file = null;
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    changeImage(file, fileList) {
      this.fileList = [file];
      this.dataForm.file = file;
    }
  },
  components: {}
};