<template>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
  >
    <template v-if="!loading">
      <Navbar />
      <Sidebar />
      <div
        class="site-content__wrapper"
        :style="{ 'min-height': documentClientHeight + 'px' }"
      >
        <keep-alive>
          <Content v-if="$route.meta.keepAlive"></Content>
        </keep-alive>
        <Content v-if="!$route.meta.keepAlive"></Content>

        <div style="margin-left: 20px;"><a href="https://beian.miit.gov.cn/" style="color: #999;" target="_blank">互联网ICP备案：辽ICP备2023007158号-1</a></div>
      </div>
    </template>
  </div>
</template>

<script>
import Navbar from "@/components/main/Navbar";
import Sidebar from "@/components/main/Sidebar";
import Content from "@/components/main/Content";
export default {
  data() {
    return {
      loading: true,
    };
  },
  provide() {
    return {
      /**
       * 传入 path 或一个 路由对象(this.$route)，返回面包屑
       * @param {Object | String} pathOrRoute path 字符串 或 路由对象(this.$route)
       * @param {Boolean} includeParents 只返回当前路由，还是包含父级面包屑
       * @return {Object | Array} 当前路由的面包屑对象，或者包含父级面包屑的数组
       */
      getBreadFromPath(pathOrRoute, includeParents = false) {
        if (!pathOrRoute) throw new Error("请传入 pathOrRoute 参数");
        let path = null,
          thisBread = null;
        if (typeof pathOrRoute === "string") path = pathOrRoute;
        else thisBread = pathOrRoute;

        // 菜单信息，能用的值只有 menuId
        let menuList = JSON.parse(sessionStorage.getItem("menuList") || "[]");
        // 真实路由信息们
        let dynamicMenuRoutes = JSON.parse(
          sessionStorage.getItem("dynamicMenuRoutes") || "[]"
        );

        if (path) {
          path = path.replace(/^\//, "");
          // 找到当前路由
          thisBread =
            dynamicMenuRoutes.find((route) => route.path === path) || {};
        }

        thisBread = setRoute(thisBread);

        // 只返回当前的面包屑信息
        if (!includeParents) return thisBread;

        // 找父级面包屑们
        let menuId = thisBread.menuId;
        if (!menuId) return;

        let menus = []; // 找到所有的 menuId 们
        search(menuList); // 从配置的菜单中，找到从顶级到该路由，所有的菜单对象们

        // 遍历 menus，依次从路由 dynamicMenuRoutes 中，查找这个菜单对应的路由
        let breadcrumbList = menus.map((item) => {
          let route = dynamicMenuRoutes.find(
            (route) => route.meta && route.meta.menuId === item.menuId
          );

          // 整理路由信息
          if (route) return setRoute(route);
          // 这个菜单是个下拉框，没有属于自己的路由
          return setMenu(item);
        });

        return breadcrumbList;
        function search(arr) {
          for (let item of arr) {
            if (item.list && item.list.length) {
              let had = search(item.list);
              if (had) {
                // 子级中找到了
                menus.unshift(item);
                return true;
              }
            } else if (item.menuId === menuId) {
              // 找到当前路由位置
              menus.unshift(item);
              return true;
            }
          }
        }
        // 过滤路由对象，返回需要的值
        function setRoute(route) {
          return {
            title: route.meta && route.meta.title,
            urlName: route.name,
            menuId: (route.meta && route.meta.menuId) || "",
          };
        }
        // 过滤菜单对象，返回需要的值
        function setMenu(menu) {
          return {
            title: menu.name,
            urlName: null,
            menuId: menu.menuId || "",
          };
        }
      },
    };
  },
  components: {
    Navbar,
    Sidebar,
    Content,
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight;
      },
      set(val) {
        this.$store.commit("common/updateDocumentClientHeight", val);
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.resetDocumentClientHeight();
  },
  methods: {
    // 重置窗口可视高度
    resetDocumentClientHeight() {
      this.documentClientHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.documentClientHeight = document.documentElement["clientHeight"];
      };
    },
    // 获取当前管理员信息
    async getUserInfo() {
      let [success,data] = await this.$store.dispatch("auth/getUserInfo");
      // console.log(data);
      if (data.code === 0) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
/* Sidebar fold
------------------------------ */
.site-sidebar--fold {
  .site-navbar__header,
  .site-navbar__brand,
  .site-sidebar,
  .site-sidebar__inner,
  .el-menu.site-sidebar__menu {
    width: 64px;
  }
  .site-navbar__body,
  .site-content__wrapper {
    margin-left: 64px;
  }
  .site-navbar__brand {
    &-lg {
      display: none;
    }
    &-mini {
      display: inline-block;
    }
  }
  .site-sidebar,
  .site-sidebar__inner {
    overflow: initial;
  }
  .site-sidebar__menu-icon {
    margin-right: 0;
    font-size: 20px;
  }
  .site-content--tabs > .breadcrumbBox {
    left: 64px;
  }
}
</style>