//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InvestorAdd from '@/components/wxView/InvestorAdd';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  category: undefined,
  name: undefined,
  page: 1,
  // 页面大小
  limit: 10 // 页码
};

export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      investorShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ""].includes(this.queryInfo.category)) {
        this.queryInfo.category = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.name)) {
        this.queryInfo.name = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(page = this.queryInfo.page || 1) {
      this.queryInfo.page = page;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosCms({
        url: `/invest/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data,'数据列表');
        let datalist = data.page;
        this.list = datalist.list || [];
        this.total = datalist.totalCount || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 limit
    changelimit(newVal) {
      this.queryInfoTemp.limit = newVal;
      this.queryInfo.limit = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      // console.log("删除的", row);
      // return;
      this.$confirm(`确认删除：[${row.name}]相关案例嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosCms.get(`/invest/delete/${row.id}`);
        // console.log(data,'删除');
        if (data.code === 200) {
          this.$message.success('删除成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    },
    // 新增
    InvestorAdd() {
      // console.log("新增的");
      this.isEdit = 'add';
      this.editForm = null;
      this.investorShow = true;
    },
    // 查看详情/编辑
    async edit(row = {}) {
      this.isEdit = 'edit';
      let that = this;
      let [success, data] = await this.$axiosCms({
        url: `/invest/get`,
        method: 'get',
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        // console.log(data.data,'案例数据列表');
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.investorShow = true;
    },
    // 获取跳转的地址
    getHref(row) {
      return row.linkUrl;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url;
      let that = this;
      var dataForm;
      if (this.isEdit === 'add') {
        url = "/invest/add";
        dataForm = {
          homeImg: form.homeImg,
          // homeImg
          category: form.category,
          // 类型
          detailsText: form.detailsText,
          // 
          coverImg: form.coverImg,
          // 封面图
          detailsImg: form.detailsImg,
          // 详情图
          name: form.name,
          // 公司简称
          fullName: form.fullName,
          // 公司全称
          sortNo: form.sortNo,
          linkUrl: form.linkUrl
        };
      } else if (this.isEdit === 'edit') {
        url = "/invest/update";
        dataForm = {
          id: form.id,
          // 修改时的主键id
          homeImg: form.homeImg,
          // homeImg
          category: form.category,
          // 类型
          detailsText: form.detailsText,
          // 
          coverImg: form.coverImg,
          // 封面图
          detailsImg: form.detailsImg,
          // 详情图
          name: form.name,
          // 公司简称
          fullName: form.fullName,
          // 公司全称
          sortNo: form.sortNo,
          linkUrl: form.linkUrl
        };
      }
      // console.log('要保存的数据：：', dataForm)
      this.loading = true;
      let token = this.$cookie.get('token');
      let [success, data] = await this.$axiosCms({
        url,
        method: 'post',
        header: {
          token
        },
        params: dataForm
      });
      this.loading = false;
      // console.log(data,'添加或者编辑');
      if (data.code == 200) {
        that.investorShow = false;
        that.$message.success('操作成功');
        that.getList(1);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    InvestorAdd
  }
};