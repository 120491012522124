//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ImgMax",
  // data() {
  //   return {}
  // },
  props: {
    src: String,
    maxWidth: Number | String,
    maxHeight: Number | String
  },
  // mounted() {},
  // methods: {},
  // watch: {},
  computed: {
    maxWidthStyle() {
      if (this.maxWidth !== undefined && this.maxWidth !== null) {
        let maxWidth = `${this.maxWidth}`;
        if (/^\d+$/.test(maxWidth)) return `max-width: ${maxWidth}px;`;else return `max-width: ${maxWidth};`;
      } else return "width: auto;";
    },
    maxHeightStyle() {
      if (this.maxHeight !== undefined && this.maxHeight !== null) {
        let maxHeight = `${this.maxHeight}`;
        if (/^\d+$/.test(maxHeight)) return `max-height: ${maxHeight}px;`;else return `max-height: ${maxHeight};`;
      } else return "height: auto;";
    }
  }
  // components: {},
};