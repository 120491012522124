//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const questioningList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      openid: undefined,
      // openid
      phone: undefined,
      // 手机
      userId: undefined,
      // 用户id
      cardName: undefined,
      // 名片姓名
      cardImg: undefined,
      // 名片照片
      post: undefined,
      // 职位
      company: undefined,
      // 公司名片
      wxNo: undefined,
      // 微信号
      qq: undefined,
      // qq
      email: undefined,
      // 邮箱
      address: undefined,
      // 地址
      state: undefined // 状态：1-正常 0-未激活数据
    };

    let dataForm = form;
    return {
      questioningList,
      dataForm,
      imgLoading: false,
      loading: false
    };
  },
  mounted() {},
  props: ['form'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.cardImg = result.ossUrl;
      }
    },
    save() {
      // console.log('名片信息保存===', this.dataForm)
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};