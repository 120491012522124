//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const typeList = [{
  value: 3,
  label: '收获物'
}, {
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      userId: form.userId,
      // 修改时的主键id
      address: form.address,
      //住址
      avatarUrl: form.avatarUrl,
      //	头像Url
      nickName: form.nickName,
      //	名称
      openid: form.openid,
      email: form.email,
      // 邮箱
      phone: form.phone // 手机号
    };

    let dataForm = form;
    return {
      dataForm,
      typeList,
      // 类型list
      stateList,
      // 状态
      imgLoading: false,
      loading: false
    };
  },
  mounted() {
    // modify==='add'?1:dataForm.state
    // console.log(this.modify)
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
  },
  props: ['form', 'modify'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.avatarUrl = result.ossUrl;
      }
    },
    save() {
      // console.log(this.dataForm);
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};