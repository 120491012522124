/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue';
import Router from 'vue-router';
// import $axios from '@/utils/axios'
import $axiosPer from '@/utils/axiosPer';
import Cookies from 'js-cookie';
import { isURL } from '@/utils/validate';
import { clearLoginInfo } from '@/utils';
// import { isAuth } from '@/utils'

Vue.use(Router);

// 需要 keepAlive 的文件路径
const needKeepAlive = ['lib/search'];
{
  // 防止跳转当前路由时的报错
  // 调整 push 方法
  const originalPush = Router.prototype.push;
  Router.prototype.push = function push(...arg) {
    let result = originalPush.call(this, ...arg);
    if (result) result.catch(_ => _);
  };
  // replace 方法同理
  const originalReplace = Router.prototype.replace;
  Router.prototype.replace = function replace(...arg) {
    let result = originalReplace.call(this, ...arg);
    if (result) result.catch(_ => _);
  };
}

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有打包环境使用懒加载
// 这里必须使用 打包过程就可以直接获取的全局变量 才可以生效
const _import = IS_BUILD === 'true' ? file => () => import('@/views/' + file + '.vue') : file => require('@/views/' + file + '.vue').default;

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [{
  path: '/404',
  component: _import('common/404'),
  name: '404',
  meta: {
    title: '404未找到'
  }
}, {
  path: '/login',
  component: _import('common/login'),
  name: 'login',
  meta: {
    title: '登录'
  }
}];

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  component: _import('main'),
  name: 'main',
  redirect: {
    name: 'home'
  },
  meta: {
    title: '主入口整体布局'
  },
  children: [
  // 通过meta对象设置路由展示方式
  // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
  // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
  // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
  // { path: '/menu', component: _import('modules/sys/menu'), name: 'menu', meta: { title: '权限' } },
  {
    path: '/home',
    component: _import('common/home'),
    name: 'home',
    meta: {
      title: '首页'
    }
  }, {
    path: '/theme',
    component: _import('common/theme'),
    name: 'theme',
    meta: {
      title: '主题'
    }
  }],
  beforeEnter(to, from, next) {
    let token = Cookies.get('token');
    if (!token || !/\S/.test(token)) {
      clearLoginInfo();
      next({
        name: 'login'
      });
    }
    next();
  }
};
const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({
    y: 0
  }),
  isAddDynamicMenuRoutes: false,
  // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
});
router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to, globalRoutes) === 'global') {
    next();
  } else {
    $axiosPer({
      url: '/sys/menu/nav',
      method: 'get'
    }).then(([success, data]) => {
      if (data.code === 0) {
        console.log("请求到的菜单路由：：：", data);
        fnAddDynamicMenuRoutes(data.menuList);
        router.options.isAddDynamicMenuRoutes = true;
        sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'));
        sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'));
        next({
          ...to,
          replace: true
        });
      } else {
        sessionStorage.setItem('menuList', '[]');
        sessionStorage.setItem('permissions', '[]');
        next();
      }
    }).catch(e => {
      console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:red');
      router.push({
        name: 'login'
      });
    });
  }
});

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
  var temp = [];
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global';
    } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
      temp = temp.concat(globalRoutes[i].children);
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main';
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  var temp = [];
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].list && menuList[i].list.length >= 1) {
      temp = temp.concat(menuList[i].list);
    } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
      menuList[i].url = menuList[i].url.replace(/^\//, '');
      var route = {
        path: menuList[i].url,
        component: null,
        name: menuList[i].url.replace(/\//, '-'),
        meta: {
          menuId: menuList[i].menuId,
          title: menuList[i].name,
          isTab: true,
          iframeUrl: '',
          keepAlive: needKeepAlive.includes(menuList[i].url)
        }
      };
      // url以http[s]://开头, 通过iframe展示
      if (isURL(menuList[i].url)) {
        route['path'] = `i-${menuList[i].menuId}`;
        route['name'] = `i-${menuList[i].menuId}`;
        route['meta']['iframeUrl'] = menuList[i].url;
      } else {
        try {
          route['component'] = _import(`modules/${menuList[i].url}`) || null;
        } catch (e) {}
      }
      routes.push(route);
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes);
  } else {
    mainRoutes.name = 'main-dynamic';
    mainRoutes.children = routes;
    router.addRoutes([mainRoutes, {
      path: '*',
      redirect: {
        name: '404'
      }
    }]);
    sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'));
    console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:red');
    // console.log(mainRoutes.children)
    console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:red');
  }
}
export default router;