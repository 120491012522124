//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import DiffSku from '@/components/lib/DiffSku'
// import DiffTest from '@/components/lib/DiffTest'

export default {
  data() {
    return {
      diffType: 'diffConfig',
      // diffConfig: 配置对比，diffTest: 测试对比
      ids: [],
      // 对比的 id 们
      loadTest: false,
      // 加载 test 数据
      configItems: null,
      // 配置对比数组
      testItems: null // 对比的测试数据条们
    };
  },

  created() {
    let query = this.$route.query;
    this.ids = (query.ids || '').split('-');
  },
  mounted() {
    // 获取需要对比的机型配置们
    this.getConfigItemList();
  },
  methods: {
    async changeDiffType() {
      if (this.diffType === 'diffTest' && !this.testItems) {
        this.loadTest = true;
        await this.getConfigItemList(true);
        this.loadTest = false;
      }
    },
    async reload() {
      this.loadTest = true;
      await this.getConfigItemList(true);
      this.loadTest = false;
    },
    // 获取需要对比的机型配置们
    async getConfigItemList(needLoadTest) {
      let [success, data] = await this.$axios({
        url: '/cms-boss/cms/computer/sku/skuTestCompare',
        method: 'post',
        data: {
          enableTest: needLoadTest ? 1 : 0,
          // 开启Test跑分测试数据对比（0:关闭、1:开启）
          skuIds: this.ids // SKU编码组
        }
      });

      if (success) {
        let configItems = [],
          testItems = [];
        (data || []).map(({
          skuTest,
          sku,
          skuId
        } = {}) => {
          skuId = `${skuId}`;
          configItems.push({
            ...sku,
            skuId
          });
          if (skuTest) testItems.push({
            ...skuTest,
            skuId
          });
        });
        if (!this.configItems) this.configItems = configItems;
        if (needLoadTest) this.testItems = testItems;
      } else {
        this.configItems = null;
        this.testItems = null;
      }
    },
    // 取消对比某条机型配置
    cancelDiff(item) {
      // 当前页删除
      this.ids = this.ids.filter(id => id !== item.skuId);
      this.configItems = this.configItems.filter(({
        skuId
      }) => skuId !== item.skuId);
      this.testItems = this.testItems.filter(({
        skuId
      }) => skuId !== item.skuId);
      // 全局存储中删除
      this.$store.dispatch('lib/removeDiffProList', item);
      // 更新url
      this.$router.replace(`${this.$route.path}?ids=${(this.ids || []).join('-')}`);
    }
  },
  computed: {},
  components: {
    // DiffSku,
    // DiffTest,
  }
};