// 业务功能的接口服务封装
// import Vue from 'vue'
import axios from 'axios';
import Cookies from 'js-cookie';
import { Message } from 'element-ui';
import router from '@/router';
import { ENV_CONFIG } from '@/utils/global.config';
import merge from 'lodash/merge';
// import store from '@/store'
import { clearLoginInfo } from '@/utils';
const service = axios.create({
  // timeout: 1000 * 60,
  baseURL: ENV_CONFIG.up,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

/**
 * 请求拦截
 */
service.interceptors.request.use(config => {
  if (config.form) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
    let data = '';
    for (let key in config.form) {
      data += encodeURIComponent(key) + '=' + encodeURIComponent(config.form[key]) + '&';
    }
    config.data = data;
  }
  if (config.method.toUpperCase() === 'GET') {
    config.params = merge({
      t: Date.now()
    }, config.params);
  }
  config.headers['token'] = Cookies.get('token'); // 请求头带上token
  return config;
}, error => {
  return Promise.reject(error);
});

/**
 * 响应拦截
 * 发起请求时，可在 url、method、data 的同级传入参数，用来配置得到响应后的处理
 */
service.interceptors.response.use(async response => {
  return await getResponse(response);
}, async err => {
  var _err$message, _err$message2;
  // 打印错误
  console.error(err);
  if (err.response) return await getResponse(err.response);

  // 超时
  if (err !== null && err !== void 0 && (_err$message = err.message) !== null && _err$message !== void 0 && _err$message.includes('timeout')) {
    Message.error('服务器连接失败'); // 请求超时
  } else if (err !== null && err !== void 0 && (_err$message2 = err.message) !== null && _err$message2 !== void 0 && _err$message2.includes('Network Error')) {
    // 网络错误
    Message.error('网络连接失败'); // 网络错误
  } else {
    Message.error('服务出错，请稍后再试！');
  }
  return [false, null, err];
});
async function getResponse(response) {
  const {
    handle = true,
    // 进行参数的 code 和其他处理?
    tip = true // 接口返回 code 非正常时，自动弹出错误提示？
    // reloadFun = null, // 重新调用该接口时，需要掉这个方法拿到新的配置（主要是 token 放在参数里了，需要重新获取）
  } = response.config;
  let success = null,
    data = null;

  // 进行数据预处理再返回给调用者
  if (handle) {
    const allData = response.data;
    if (!allData) {
      success = false;
    } else {
      success = allData.code === 200;
      if (success) {
        data = allData.data;
      } else {
        // success 为 false 的处理
        data = allData;
        if (response.data && response.data.code === 401) {
          // 401, token失效
          clearLoginInfo();
          router.push({
            name: 'login'
          });
        }

        // 未登录或 token 过期，尝试需要刷新 token
        // if ([11013, 11014, 11015, 11016].includes(allData.code)) {
        //   // 刷新 token
        //   let [isSuccess] = await store.dispatch('auth/refreshToken')
        //   // 刷新 token 成功，直接再去调刚才这个接口
        //   if (isSuccess) return reloadFun ? reloadFun() : $axios(response.config)
        //   else await store.dispatch('auth/logout', false)
        // }

        if (tip) {
          // 需要弹框提醒
          if (allData.msg) Message.error(allData.msg);else Message.error('接口服务出错');
        }
      }
    }
  }
  return [success, data, response];
}
export default service;