//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddShop from "@/components/teahouseManage/AddShop";
import addShopImgShow from "@/components/teahouseManage/ShopEdit";
import { getImageList, deleteImage, uploadCommodityDetail } from "./api";

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  state: undefined,
  phone: undefined,
  type: undefined,
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
const tagList = [{
  value: "店铺图",
  label: "店铺图"
}, {
  value: "民宿宣传图",
  label: "民宿宣传图"
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      addForm: null,
      addShopShow: false,
      // 添加 编辑的弹框是否显示
      addShopImgShow: false,
      // 添加店铺相关图片
      shopInfo: null,
      // 店铺相关信息
      shopId: null,
      imgLoading: false,
      dialogAddVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      tagList,
      optTag: "店铺图",
      fileList: [],
      fileTag: null,
      sourceFlag: null
    };
  },
  mounted() {
    this.getImageListHandle();
  },
  methods: {
    search() {
      this.getImageListHandle();
    },
    // 获取图片列表
    async getImageListHandle() {
      let {
        data
      } = await getImageList(this.optTag);
      this.list = data;
      console.log("resp", data);
    },
    changeFileHandle(file, fileList) {
      this.fileList = fileList;
      console.log("changeFileHandle", file, fileList, this.fileList);
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
      console.log("index", index);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传民宿图片
    async uploadMinSuImage() {
      console.log(this.fileTag);
      if (!this.fileTag) {
        this.$message("请先选择图片类型");
        return;
      }
      if (this.fileTag == '店铺图' && !this.sourceFlag) {
        this.$message("上传店铺图时，区域标签必填");
        return;
      }
      if (this.fileList.length == 0) {
        this.$message("请先选择图片");
        return;
      }
      let resp = await uploadCommodityDetail({
        tag: this.fileTag,
        fileArr: this.fileList.map(item => {
          return item.raw;
        }),
        sourceFlag: this.sourceFlag
      });
      if (resp.code == 200) {
        this.$message({
          type: "success",
          message: "上传成功"
        });
        this.getImageListHandle();
        this.dialogAddVisible = false;
        this.fileList = [];
      }
    },
    addImageHandle() {
      this.dialogAddVisible = true;
      this.fileList = [];
    },
    // 获取列表
    async getList() {
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/shop/get`,
        method: "get"
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data, '获取到的店铺管理')
        this.shopId = data.data.id;
        // return
        this.shopInfo = data.data;
        this.list = data.data.fileList || [];
        this.total = 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 查看详情/编辑
    edit(row = {}) {
      this.isEdit = "edit";
      this.editForm = this.shopInfo;
      this.addShopShow = true;
    },
    // 获取详情
    async getInfo(row = {}) {
      let that = this;
      let [success, data] = await this.$axiosTea({
        url: `/shop/get`,
        method: "get",
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        console.log(data.data, "店铺详情");
        that.editForm = this.shopInfo;
      } else {
        that.editForm = null;
      }
      this.addShopShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url = "/shop/save";
      let that = this;
      var dataForm;
      dataForm = {
        id: form.id,
        // 修改时的主键id
        address: form.address,
        // 地址
        email: form.email,
        //	邮箱
        name: form.name,
        //	名称
        remark: form.remark,
        // 备注
        phone: form.phone //手机号
      };

      this.loading = true;
      let token = this.$cookie.get("token");
      let [success, data] = await this.$axiosTea({
        url,
        method: "post",
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.addShopShow = false;
        that.$message.success("操作成功");
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    },
    // 新增店铺相关图片
    addShopImg(row = {}) {
      this.addForm = {};
      this.addShopImgShow = true;
    },
    // 删除类型数据
    remove(row) {
      // console.log(row)
      // return
      this.$confirm(`确认删除该图片嘛？`).then(async () => {
        let resp = await deleteImage(row.id);
        if (resp.code == 200) {
          this.$message.success("删除成功！");
          this.getImageListHandle();
        } else {
          this.$message.fail("删除失败");
        }
        // this.$set(row, "removing", true);
        // let [success, data] = await this.$axiosTea({
        //   url: `/shop/img/del`,
        //   method: "get",
        //   params: { fileId: row.id },
        // });
        // // console.log(data, '删除返回')
        // if (data.code === 200) {
        //   this.$message.success("删除成功！");
        //   this.getList(1);
        // } else {
        //   this.$set(row, "removing", false);
        // }
        // console.log("this.$set", this.$set);
      }).catch(_ => _);
    },
    // 图片上传完成
    async upLoadData(result) {
      console.log("拿到的图片文件：：：", result);
      this.imgLoading = false;
      let that = this;
      let dataForm = new FormData();
      dataForm.append("file", result);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: "/shop/ui/upload",
        method: "post",
        data: dataForm,
        headers: {
          "content-type": "multipart/form-data"
        }
      });
      this.loading = false;
      if (data.code == 200) {
        that.addShopImgShow = false;
        that.$message.success("操作成功");
        // that.getList(1)
        console.log(data.data);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    AddShop,
    addShopImgShow
  }
};