//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FramManagePage from '@/components/smartFarm/FramManagePage';
// import HarvestManagePage from '@/components/smartFarm/HarvestManagePage'
export default {
  data() {
    return {
      farmShow: 'farmManage'
    };
  },
  mounted() {},
  methods: {},
  components: {
    FramManagePage
  }
};