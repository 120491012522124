import $axiosPer from '@/utils/axiosPer';
import Cookies from 'js-cookie';
export default {
  namespaced: true,
  state: {
    userInfo: {}
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data || {};
    }
  },
  actions: {
    // 登录
    async login({}, reqData) {
      const result = await $axiosPer({
        url: '/sys/login',
        method: 'post',
        data: reqData
      });
      let [success, data] = result;
      // console.log(data);
      // 登录成功
      if (data.code == 0) {
        const {
          token
        } = data;
        // console.log(token);
        Cookies.set('token', token);
      } else {
        Cookies.remove('token');
      }
      return result;
    },
    // 获取当前管理员信息
    async getUserInfo({
      commit
    }) {
      const result = await $axiosPer({
        url: '/sys/user/info',
        method: 'get'
      });
      let [success, data] = result;
      // console.log(data, '信息');
      if (data.code == 0) {
        const user = data.user;
        commit('setUserInfo', user);
      } else {
        Cookies.remove('userId');
      }
      return result;
    }
    // // 刷新 token
    // async refreshToken({rootState, dispatch, commit}) {
    //   let refresh_token = rootState.cookie.refresh_token
    //   if (!refresh_token) return [false]

    //   let result = await $axios({
    //     url: '/portal/cms/auth/token/refresh',
    //     method: 'post',
    //     tip: false,
    //     params: {
    //       refreshToken: refresh_token
    //     },
    //   })

    //   const [ success, data ] = result
    //   if (success) {
    //     const { access_token, refresh_token } = data
    //     dispatch('cookie/set', ['access_token', access_token], { root: true })
    //     dispatch('cookie/set', ['refresh_token', refresh_token], { root: true })
    //   }
    //   return result
    // },
    // // token 校验
    // async tokenCheck({rootState}) {
    //   let axiosConfig = () => {
    //     return $axios({
    //       url: '/portal/cms/auth/token/check',
    //       method: 'post',
    //       reloadFun: axiosConfig,
    //       params: {
    //         token: rootState.cookie.access_token
    //       },
    //     })
    //   }
    //   return axiosConfig()
    // },
    // // 退出登录
    // async logout({rootState, dispatch, commit}, needSendApi = true) {
    //   let result = []
    //   if (needSendApi) {
    //     let access_token = rootState.cookie.access_token
    //     if (access_token) {
    //       result = await $axios({
    //         url: '/portal/cms/auth/token/revoke',
    //         method: 'post',
    //         handle: false,
    //         params: {
    //           token: access_token
    //         },
    //       })
    //     }
    //   }
    //   dispatch('cookie/remove', 'access_token', { root: true })
    //   dispatch('cookie/remove', 'refresh_token', { root: true })
    //   commit('setUserInfo')
    //   return result
    // },
    // // 获取用户信息(其他信息)
    // async getUserInfo({commit}) {
    //   let result = await $axios({
    //     url: '/portal/cms/user/getInfo',
    //     method: 'get',
    //   })
    //   const [ success, data ] = result
    //   commit('setUserInfo', success ? data : null)
    //   return result
    // },
  }
};