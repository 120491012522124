//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'scheduleAddOrUpdate',
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        beanName: '',
        params: '',
        cronExpression: '',
        remark: '',
        status: 0
      },
      dataRule: {
        beanName: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        cronExpression: [{
          required: true,
          message: 'cron表达式不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          // this.$http({
          //   url: this.$http.adornUrl(`/sys/schedule/info/${this.dataForm.id}`),
          //   method: 'get',
          //   params: this.$http.adornParams()
          // })
          this.$axiosPer({
            url: `/sys/schedule/info/${this.dataForm.id}`,
            method: 'get',
            params: this.$http.adornParams()
          }).then(res => {
            let data = res[1].schedule;
            // console.log("scheduledata", res, data);
            if (data && data.status === 0) {
              this.dataForm.beanName = data.beanName;
              this.dataForm.params = data.params;
              this.dataForm.cronExpression = data.cronExpression;
              this.dataForm.remark = data.remark;
              this.dataForm.status = data.status;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/sys/schedule/${!this.dataForm.id ? 'save' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              jobId: this.dataForm.id || undefined,
              beanName: this.dataForm.beanName,
              params: this.dataForm.params,
              cronExpression: this.dataForm.cronExpression,
              remark: this.dataForm.remark,
              status: !this.dataForm.id ? undefined : this.dataForm.status
            })
          }).then(({
            data
          }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};