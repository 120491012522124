//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const typeList = [{
  value: 3,
  label: '收获物'
}, {
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      id: form.id,
      // 修改时的主键id
      productType: form.productType,
      //1-蔬菜、2-家禽、3-收获物(如鸡蛋)
      state: form.state,
      //	状态：1-正常(新增时默认);0-冻结
      remark: form.remark,
      // 备注
      number: form.number,
      // 数量
      farmProductId: form.farmProductId,
      //
      farmId: form.farmId
    };
    let dataForm = form;
    return {
      dataForm,
      typeList,
      // 类型list
      stateList,
      // 状态
      loading: false,
      allProductsList: [],
      // 所有的农产品
      farmList: [] // 农场列表
    };
  },

  mounted() {
    // console.log(this.modify)
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
    this.getList(this.dataForm.type);
    this.getFarmList();
  },
  props: ['form', 'modify'],
  methods: {
    // => (dataForm.type = newVal.value)
    typeChange(newVal) {
      // this.dataForm.farmProductId= null
      this.dataForm.type = newVal.value;
      this.getList(this.dataForm.type);
    },
    // 获取列表
    async getList(types) {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/product/findList`,
        method: 'get',
        params: {
          type: types,
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows,'农产品数据');
        // return
        let datalist = data.data;
        this.allProductsList = datalist.rows || [];
      } else {
        this.allProductsList = [];
      }
    },
    async getFarmList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/findList`,
        method: 'get',
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log('获取到的农场数据', data.data)
        let datalist = data.data;
        this.farmList = datalist.rows || [];
      } else {
        this.farmList = [];
      }
    },
    save() {
      this.dataForm.number = this.dataForm.number * 1;
      // console.log(this.dataForm);
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};