//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPositionHandle, savePositionHandle, deletePosition } from "./api";
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {
        id: null,
        price: null,
        position: "",
        sort: null
      },
      formLabelWidth: "120px"
    };
  },
  created() {
    this.getRights();
  },
  methods: {
    handleClick(row) {
      this.dialogFormVisible = true;
      this.form = row;
    },
    async getRights() {
      let {
        data
      } = await getPositionHandle();
      this.tableData = data;
      console.log("resp", data);
    },
    addRightsHandle() {
      this.dialogFormVisible = true;
      this.form = {};
    },
    async confirmEditRightsHandle() {
      console.log(this.form);
      if (!this.form.position) {
        this.$message("位置名称必填");
        return;
      }
      if (!this.form.price) {
        this.$message("所需积分必填");
        return;
      }
      let resp = await savePositionHandle({
        id: this.form.id,
        position: this.form.position,
        price: this.form.price,
        sort: this.form.sort
      });
      if (resp.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.getRights();
      } else {
        this.$message({
          message: resp.msg,
          type: "error"
        });
      }
      this.dialogFormVisible = false;
    },
    // 删除权益
    async deteteRights(row) {
      console.log("deteteRights", row);
      this.$confirm("确定要删除该位置吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        let value = await deletePosition(row.id);
        if (value.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getRights();
        } else {
          this.$message({
            message: value.msg,
            type: "error"
          });
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    }
  }
};