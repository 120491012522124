//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const typeList = [{
  value: 3,
  label: '收获物'
}, {
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || [];
    let dataForm = form;
    return {
      dataForm,
      typeList,
      // 类型list
      stateList,
      // 状态
      loading: false
    };
  },
  mounted() {},
  props: ['form'],
  methods: {
    save() {
      // console.log(this.dataForm);
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};