//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TeaSpecialistAdd from "@/components/teahouseManage/TeaSpecialistAdd";
import EditCommidityDetail from "@/components/teahouseManage/EditCommidityDetail";
import { uploadTeaMan, getImageList2 } from "./api";

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  name: undefined,
  number: undefined,
  phone: undefined,
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      teaSpecialistAddShow: false,
      // 添加 编辑的弹框是否显示
      isShowEditDetail: false,
      //详情图管理
      idValue: null //主键id
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ""].includes(this.queryInfo.name)) {
        this.queryInfo.name = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.number)) {
        this.queryInfo.number = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.phone)) {
        this.queryInfo.phone = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/shop/specialist/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '茶艺师管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 新增
    teaSpecialistAdd() {
      this.isEdit = "add";
      this.editForm = {};
      this.teaSpecialistAddShow = true;
    },
    // 查看详情/编辑
    edit(row = {}) {
      this.isEdit = "edit";
      this.editForm = row;
      this.teaSpecialistAddShow = true;
    },
    // 详情图管理
    async detailHandle(row) {
      this.idValue = row.id;
      this.isShowEditDetail = true;
      this.tag = '茶艺师详情图';
    },
    // 删除类型数据
    remove(row) {
      this.$confirm(`确认变更{${row.name}}茶艺师的状态吗？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosTea({
          url: `/shop/specialist/uptState`,
          method: "get",
          params: {
            id: row.id
          }
        });
        // console.log(data, '删除返回')
        if (data.code === 200) {
          this.$message.success("执行成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
        }
      }).catch(_ => _);
    },
    // 获取详情
    // async getInfo(row = {}) {
    //   let that = this;
    //   let [success, data] = await this.$axiosTea({
    //     url: `/shop/specialist/get`,
    //     method: "get",
    //     params: { id: row.id },
    //   });
    //   if (data.code == 200) {
    //     console.log(data.data, "茶艺师详情");
    //     that.editForm = data.data;
    //   } else {
    //     that.editForm = null;
    //   }
    //   this.teaSpecialistAddShow = true;
    // },
    // 新增或者修改的返回
    async successAdd(form) {
      console.log("form:", form);
      if (this.isEdit === "add") {
        form.id = null;
      }
      this.loading = true;
      let data = await uploadTeaMan(form);
      this.loading = false;
      if (data.code == 200) {
        this.teaSpecialistAddShow = false;
        this.$message.success("操作成功");
        this.getList(1);
      } else {
        this.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    TeaSpecialistAdd,
    EditCommidityDetail
  }
};