//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BookingEdit from "@/components/teahouseManage/BookingEdit";
// 预约状态 预约状态：预约中(0), 已完成(1), 取消预约(9)
const reserveStateList = [{
  value: 0,
  label: "预约中"
}, {
  value: 1,
  label: "已完成"
}, {
  value: 9,
  label: "取消预约"
}];

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  phone: undefined,
  nickName: undefined,
  reserveState: undefined,
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  name: "yuyue",
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      reserveStateList,
      // 预约状态
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      bookingEditShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      // pc预约状态：0-待结算 1-待发货 3-配送中 2-待自取 5-已收货 8-已完成 9-已取消
      if ([undefined, null, ""].includes(this.queryInfo.nickName)) {
        this.queryInfo.nickName = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.phone)) {
        this.queryInfo.phone = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.reserveState)) {
        this.queryInfo.reserveState = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/member/reserve/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '预约管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 修改状态
    async changeStatus(row, newVal) {
      // /order/shop/update
      console.log(newVal, "修改状态", row);
      this.$confirm(`确认修改该订单状态嘛？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosTea({
          url: `/member/reserve/updateState`,
          method: "post",
          params: {
            id: row.id
          }
        });
        // console.log(data, '修改状态返回')
        if (data.code === 200) {
          this.$message.success("修改状态成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
        }
      }).catch(_ => _);
    },
    // 查看详情/编辑
    edit(row = {}) {
      this.isEdit = "edit";
      // this.editForm = row.id
      this.getInfo(row);
    },
    // 获取详情
    async getInfo(row = {}) {
      let that = this;
      let [success, data] = await this.$axiosTea({
        url: `/member/reserve/get`,
        method: "get",
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.bookingEditShow = true;
    }
  },
  // watch: {},
  // computed: {},
  components: {
    BookingEdit
  }
};