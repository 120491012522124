//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const stateList = [{
  value: 0,
  label: '未配送'
}, {
  value: 1,
  label: '配送中'
}, {
  value: 7,
  label: '配送完成'
}, {
  value: 9,
  label: '已取消'
}];
export default {
  data() {
    let form = this.form || {
      orderId: form.orderId,
      // 修改时的主键id
      farmId: form.farmId,
      deliveryNumber: form.deliveryNumber,
      //1-蔬菜、2-家禽、3-收获物(如鸡蛋)
      deliveryTime: form.deliveryTime,
      //	状态：1-正常(新增时默认);0-冻结
      detailed: form.detailed,
      // 备注
      orderState: form.orderState,
      // 数量
      phone: form.phone,
      //
      remark: form.remark,
      //
      serveTime: form.serveTime,
      //
      userName: form.userName //
    };

    let dataForm = form;
    return {
      dataForm,
      stateList,
      // 状态
      loading: false,
      farmList: [] // 农场列表
    };
  },

  mounted() {
    this.getFarmList();
  },
  props: ['form'],
  methods: {
    async getFarmList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/findList`,
        method: 'get',
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log('获取到的农场数据', data.data)
        let datalist = data.data;
        this.farmList = datalist.rows || [];
      } else {
        this.farmList = [];
      }
    },
    // 修改订单状态
    changeorderState(row) {
      this.$confirm(`确认修改该订单状态嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosWi({
          url: `/farm/order/update`,
          method: 'post',
          params: {
            orderId: row.orderId,
            orderState: row.orderState,
            deliveryNumber: row.deliveryNumber
          }
        });
        if (data.code === 200) {
          this.$message.success('修改成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    }
  },
  components: {}
};