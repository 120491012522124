//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      list: [],
      form: {},
      total: 0,
      tableKey: 1 // table表格组件的key
    };
  },

  props: ['orderId'],
  mounted() {
    console.log(this.orderId, '99900祭祀点击');
    this.getList(this.orderId);
  },
  methods: {
    // 获取列表
    async getList(id) {
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/order/shop/get`,
        method: 'get',
        params: {
          orderId: id
        }
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.shopCartList, '订单详情')
        // return
        let orderData = data.data;
        this.list = orderData.shopCartList || [];
        if (orderData.orderState == 5 || orderData.orderState == 8) {
          this.dialogFormVisible = true;
          this.form = orderData;
        }
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    }
    // 修改每页条数 pageSize
    // changepageSize(newVal) {
    //   this.queryInfoTemp.pageSize = newVal
    //   this.queryInfo.pageSize = newVal
    //   this.getList()
    // },
  },

  // watch: {},
  // computed: {},
  components: {}
};