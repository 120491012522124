//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: "积分",
  label: "积分"
}, {
  value: "金币",
  label: "金币"
}];
const types = [{
  value: 0,
  label: "支出"
}, {
  value: 1,
  label: "收入"
}];
export default {
  name: "yuyue",
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      types,
      //   
      stateList,
      // 状态
      state: "积分",
      start: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
      end: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
      response: {
        incomeTotal: null,
        expendTotal: null
      }
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      if ([undefined, null, ""].includes(this.state)) {
        this.state = "积分";
      }
      if (![undefined, null, ""].includes(this.start)) {
        queryInfo.start = this.start.split("-").join("/");
      }
      if (![undefined, null, ""].includes(this.end)) {
        queryInfo.end = this.end.split("-").join("/");
      }
      let url = `/member/money/record`;
      if ("金币" == this.state) {
        url = `/member/gold/record`;
      }
      let [success, data] = await this.$axiosTea({
        url: url,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '预约管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
        this.response = data.response;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    }
  },
  // watch: {},
  // computed: {},
  components: {}
};