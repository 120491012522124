// import router from './../router'
// import store from './index'

const getters = {
  userId: state => {
    var _state$auth, _state$auth$userInfo;
    return ((_state$auth = state.auth) === null || _state$auth === void 0 ? void 0 : (_state$auth$userInfo = _state$auth.userInfo) === null || _state$auth$userInfo === void 0 ? void 0 : _state$auth$userInfo.userId) || undefined;
  },
  userName: state => {
    var _state$auth2, _state$auth2$userInfo;
    return ((_state$auth2 = state.auth) === null || _state$auth2 === void 0 ? void 0 : (_state$auth2$userInfo = _state$auth2.userInfo) === null || _state$auth2$userInfo === void 0 ? void 0 : _state$auth2$userInfo.username) || undefined;
  }
};
export default getters;