<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="site-content">
        <div class="brand-info">
          <h2 class="brand-info__text">{{ webTitle }}</h2>
          <p class="brand-info__intro">------------------------------------</p>
        </div>
        <div class="login-main">
          <h3 class="login-title">管理员登录</h3>
          <div class="space-10"></div>
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            :disabled="loading"
            @keyup.enter.native="dataFormSubmit()"
            status-icon
          >
            <el-form-item prop="userName">
              <el-input
                v-model="dataForm.userName"
                placeholder="帐号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="dataForm.password"
                type="password"
                placeholder="密码"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="captcha">
              <el-row :gutter="20">
                <el-col :span="14">
                  <el-input v-model="dataForm.captcha" placeholder="验证码">
                  </el-input>
                </el-col>
                <el-col :span="10" class="login-captcha">
                  <img :src="captchaPath" @click="getCaptcha" alt="" />
                </el-col>
              </el-row>
            </el-form-item> -->
            <el-form-item>
              <el-button
                :loading="loading"
                class="login-btn-submit"
                type="primary"
                @click="dataFormSubmit()"
                >登录</el-button
              >
            </el-form-item>
          </el-form>

          <div class="beianhao"><a href="https://beian.miit.gov.cn/" target="_blank">互联网ICP备案：辽ICP备2023007158号-1</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUUID } from "@/utils";
import { ENV_CONFIG, webTitle } from "@/utils/global.config";

export default {
  data() {
    return {
      loading: false,
      dataForm: {
        userName: "",
        password: "",
        uuid: "",
        webTitle: "",
        // captcha: "",
      },
      dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        // captcha: [
        //   { required: true, message: "验证码不能为空", trigger: "blur" },
        // ],
      },
      captchaPath: "",
    };
  },
  created() {
    this.getCaptcha();
    this.webTitle = webTitle;
  },
  methods: {
    // 提交表单
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (!valid) return;
        this.loading = true;
        let [success, data] = await this.$store.dispatch("auth/login", {
          username: this.dataForm.userName,
          password: this.dataForm.password,
          uuid: this.dataForm.uuid,
          // code: this.dataForm.captcha,
        });
        this.loading = false;
        if (data?.code !== 0)
          this.$message.error(data?.msg || "接口出错，请稍后再试！");

        if (data?.code == 0) {
          this.$router.replace({ name: "home" });
        } else {
          this.getCaptcha();
        }
      });
    },
    // 获取验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID();
      this.captchaPath = `${ENV_CONFIG.cms}/captcha.jpg?uuid=${this.dataForm.uuid}`;
      // this.captchaPath = `http://10.0.0.128:9090/captcha.jpg?uuid=${this.dataForm.uuid}`
    },
  },
};
</script>

<style lang="scss">
.site-wrapper.site-page--login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(38, 50, 56, 0.3);
  overflow: hidden;
  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(~@/assets/img/login_bg.png);
    background-size: cover;
  }
  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }
  .site-content {
    min-height: 100%;
    padding: 30px 500px 30px 30px;
  }
  .brand-info {
    margin: 220px 100px 0 90px;
    color: #fff;
  }
  .brand-info__text {
    margin: 0 0 30px 80px;
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .brand-info__intro {
    margin: 10px 0 10px 80px;
    font-size: 16px;
    line-height: 1.58;
    opacity: 0.6;
  }
  .login-main {
    position: absolute;
    top: 0;
    right: 0;
    padding: 150px 60px 180px;
    width: 470px;
    min-height: 100%;
    background-color: #fff;
  }
  .login-title {
    font-size: 16px;
  }
  .login-captcha {
    overflow: hidden;
    > img {
      width: 100%;
      cursor: pointer;
    }
  }
  .login-btn-submit {
    width: 100%;
    margin-top: 38px;
  }
  .beianhao {
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%)
}
}
</style>
