//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const typeList = [{
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
const growStateList = [{
  value: 1,
  label: '幼苗'
}, {
  value: 3,
  label: '成长期'
}, {
  value: 7,
  label: '成熟'
}, {
  value: 9,
  label: '枯死'
}, {
  value: 0,
  label: '未知'
}];
export default {
  data() {
    typeList;
    let form = this.form || {
      id: form.id,
      // 修改时的主键id
      farmId: form.farmId,
      //	农场种植区主键
      farmProductId: form.farmProductId,
      //		种植养殖的农产品主键
      growState: form.growState,
      //		生长状态：幼苗(1),成长期(3),成熟(7),枯死(9),未知(0)
      number: form.number,
      // 种植块序号or家禽编号
      pedometer: form.pedometer,
      // 	家禽计步数
      productType: form.productType,
      // 	1-蔬菜、2-家禽
      remark: form.remark,
      // 备注
      state: form.state // 备注
    };

    let dataForm = form;
    return {
      dataForm,
      typeList,
      // 类型list
      stateList,
      // 状态
      growStateList,
      // 生长状态list
      loading: false,
      farmList: [],
      // 农场列表
      allProductsList: [] // 所有的农产品
    };
  },

  mounted() {
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
    this.getFarmList();
    this.getList(this.dataForm.productType);
  },
  props: ['form', 'modify'],
  methods: {
    async getFarmList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/findList`,
        method: 'get',
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log('获取到的农场数据', data.data)
        let datalist = data.data;
        this.farmList = datalist.rows || [];
      } else {
        this.farmList = [];
      }
    },
    // 获取列表
    async getList(types) {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/product/findList`,
        method: 'get',
        params: {
          type: types,
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows,'农产品数据');
        // return
        let datalist = data.data;
        this.allProductsList = datalist.rows || [];
      } else {
        this.allProductsList = [];
      }
    },
    typeChange(newVal) {
      // this.dataForm.farmProductId= null
      this.dataForm.productType = newVal.value;
      // console.log()
      this.getList(this.dataForm.productType);
    },
    save() {
      // console.log(this.dataForm);
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};