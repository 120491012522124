//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      dataForm: {
        number: "",
        userId: this.form.userId,
        remark: "",
        type: null
      },
      loading: false,
      tagList: []
    };
  },
  mounted() {
    this.tagList = [];
    // console.log("结算带过来的：：：", this.form);
    if (this.isAuth('member:gold:add') || this.isAuth('member:integral:add')) {
      this.tagList.push({
        value: "1",
        label: "充值"
      });
    }
    if (this.isAuth('member:gold:reduce') || this.isAuth('member:integral:reduce')) {
      this.tagList.push({
        value: "0",
        label: "消费"
      });
    }
    console.log(this.tagList);
    if (this.tagList.length > 0) {
      this.dataForm.type = this.tagList[0].value;
    }
  },
  props: ["form"],
  methods: {
    save() {
      if (!this.dataForm.number) {
        this.$notify({
          title: "警告",
          message: "请输入修改金额",
          type: "warning"
        });
      } else {
        this.$confirm("确定要提交吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$emit("success", this.dataForm);
        }).catch(() => {});
      }
    }
  },
  components: {}
};