//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EditCard from '@/components/szrs/EditCard';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  page: 1,
  // 页面大小
  limit: 10 // 页码
};

export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      editForm: null,
      // 编辑传输的数据
      cardShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 获取列表
    async getList(page = this.queryInfo.page || 1) {
      this.queryInfo.page = page;
      let queryInfo = this.cloneDeep(this.queryInfo);
      // return;
      this.loading = true;
      let token = this.$cookie.get('token');
      let [success, data] = await this.$axiosSzrs({
        url: `/wx/mini/findList`,
        method: 'get',
        header: {
          token
        },
        params: queryInfo
      });
      this.loading = false;
      // console.log(data);
      if (data.code === 200) {
        this.list = data.page.list || [];
        // console.log(data.page.list, '数据')
        this.total = data.page.totalCount || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 limit
    changelimit(newVal) {
      this.queryInfoTemp.limit = newVal;
      this.queryInfo.limit = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      this.$confirm(`确认删除：[${row.cardName}]名片嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosSzrs({
          url: `/wx/mini/del`,
          method: 'get',
          params: {
            userId: row.userId
          }
        });
        if (data.code === 200) {
          this.$message.success('删除成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    },
    // 新增
    EditCard() {
      // console.log("新增的");
    },
    // 查看详情/编辑
    async edit(row = {}) {
      let that = this;
      let [success, data] = await this.$axiosSzrs({
        url: `/wx/mini/get`,
        method: 'get',
        params: {
          openid: row.openid
        }
      });
      if (data.code === 200) {
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      that.cardShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      let that = this;
      var dataForm = {
        openid: form.openid,
        // openid
        phone: form.phone,
        // 手机
        userId: form.userId,
        // 用户id
        cardName: form.cardName,
        // 名片姓名
        cardImg: form.cardImg,
        // 名片照片
        post: form.post,
        // 职位
        company: form.company,
        // 公司名片
        wxNo: form.wxNo,
        // 微信号
        qq: form.qq,
        // qq
        email: form.email,
        // 邮箱
        address: form.address,
        // 地址
        state: form.state // 状态：1-正常 0-未激活数据
      };
      // console.log('要保存的数据：：', dataForm)
      this.loading = true;
      let [success, data] = await this.$axiosSzrs({
        url: `/wx/mini/saveCard`,
        method: 'post',
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.cardShow = false;
        that.$message.success('操作成功');
        that.getList(1);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    EditCard
  }
};