//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getRightsHandle, addRightsHandle, deleteRights } from "./api";
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {
        name: "",
        description: ""
      },
      formLabelWidth: "120px"
    };
  },
  created() {
    this.getRights();
  },
  methods: {
    handleClick(row) {
      this.dialogFormVisible = true;
      this.form = row;
    },
    async getRights() {
      let {
        data
      } = await getRightsHandle();
      this.tableData = data;
      console.log("resp", data);
    },
    addRightsHandle() {
      this.dialogFormVisible = true;
      this.form = {};
    },
    async confirmEditRightsHandle() {
      let resp = await addRightsHandle({
        name: this.form.name,
        description: this.form.description
      });
      console.log("confirmEditRightsHandle", resp);
      if (resp.code == 200) {
        this.$message({
          message: "新增成功",
          type: "success"
        });
        this.getRights();
      } else {
        this.$message({
          message: resp.msg,
          type: "error"
        });
      }
      this.dialogFormVisible = false;
    },
    // 删除权益
    async deteteRights(row) {
      console.log("deteteRights", row);
      this.$confirm("确定要删除该权益吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        let value = await deleteRights(row.id);
        if (value.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getRights();
        } else {
          this.$message({
            message: value.msg,
            type: "error"
          });
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    }
  }
};