//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrderEdit from "@/components/teahouseManage/OrderEdit";
import { uploadOrderStatus } from "./api";

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  orderState: undefined,
  deliveryType: undefined,
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
// pc订单状态：0-待结算 1-待发货 3-配送中 2-待自取 5-已收货 8-已完成 9-已取消
const orderStateList = [{
  value: 1,
  label: "待发货"
}, {
  value: 2,
  label: "待自取"
}, {
  value: 3,
  label: "配送中"
}, {
  value: 4,
  label: "待住宿"
}, {
  value: 5,
  label: "已完成"
}, {
  value: 8,
  label: "已评价"
}, {
  value: 9,
  label: "已取消"
}];
const deliveryTypeList = [{
  value: "邮寄",
  label: "邮寄"
}, {
  value: "自取",
  label: "自取"
}, {
  value: "住宿",
  label: "住宿"
}];
export default {
  name: "orderManage",
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      stateList,
      // 状态
      orderStateList,
      // 订单状态
      deliveryTypeList,
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      orderEditShow: false,
      // 添加 编辑的弹框是否显示
      showYouji: false,
      gridData: {},
      ziquData: {},
      minsuData: {},
      showZiqu: false,
      showMinsu: false,
      value2: "",
      value3: "",
      value4: "",
      value5: ""
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      // pc订单状态：0-待结算 1-待发货 3-配送中 2-待自取 5-已收货 8-已完成 9-已取消
      if ([undefined, null, ""].includes(this.queryInfo.orderState)) {
        this.queryInfo.orderState = undefined;
      }
      this.getList(1);
    },
    changeDate(value) {
      if (this.showYouji) {
        this.gridData.deliveryTime = value.split("-").join("/");
        console.log("value", value, this.gridData.deliveryTime);
        return;
      }
      //离店时间
      if (this.showMinsu) {
        this.minsuData.haveTime = value.split("-").join("/");
        return;
      }
    },
    changeDate2(value) {
      if (this.showZiqu) {
        this.ziquData.haveTime = value.split("-").join("/");
        return;
      }
      //入住时间
      if (this.showMinsu) {
        this.minsuData.stayTime = value.split("-").join("/");
        return;
      }
      console.log("value", value, this.ziquData.haveTime);
    },
    //订单管理
    manageOrder(row) {
      console.log("row", row);
      if (row.deliveryType == "邮寄") {
        if (row.deliveryTime) {
          row.deliveryTime = row.deliveryTime.split(" ")[0].split("-").join("/");
        }
        this.gridData = row;
        this.value2 = row.deliveryTime || "";
        this.gridData.orderState = "3";
        this.showYouji = true;
      } else if (row.deliveryType == "自取") {
        this.ziquData = row;
        this.showZiqu = true;
      } else if (row.deliveryType == "住宿") {
        this.minsuData = row;
        this.showMinsu = true;
      }
    },
    async confirmOrderStatusHandle() {
      //邮寄提交
      let {
        deliveryNumber,
        deliveryTime,
        orderId,
        orderState
      } = this.gridData;
      if (!deliveryNumber || !deliveryTime) {
        this.$message({
          type: "warning",
          message: "请输入物流单号、配送时间"
        });
        return;
      }
      let resp = await uploadOrderStatus({
        orderId,
        orderState,
        deliveryNumber,
        deliveryTime
      });
      console.log("resp", resp);
      if (resp.code == 200) {
        this.$message({
          type: "success",
          message: "更改成功"
        });
        this.showYouji = false;
        this.gridData = {};
        this.getList(1);
      } else {
        this.$message({
          type: "fail",
          message: resp.msg
        });
      }
    },
    async confirmZiquHandle() {
      let {
        orderId,
        haveTime
      } = this.ziquData;
      if (!haveTime) {
        this.$message({
          type: "warning",
          message: "请选择取货时间"
        });
        return;
      }
      let resp = await uploadOrderStatus({
        orderId,
        haveTime
      });
      console.log("resp", resp);
      if (resp.code == 200) {
        this.$message({
          type: "success",
          message: "更改成功"
        });
        this.showZiqu = false;
        this.ziquData = {};
        this.getList(1);
      } else {
        this.$message({
          type: "fail",
          message: resp.msg
        });
      }
    },
    async confirmMinsuHandle() {
      let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let {
        orderId,
        userCardNum,
        stayTime,
        haveTime
      } = this.minsuData;
      if (!userCardNum || !stayTime) {
        this.$message({
          type: "warning",
          message: "请输入身份证号、选择入住时间"
        });
        return;
      }
      if (userCardNum) {
        let value = p.test(userCardNum);
        if (!value) {
          this.$message({
            type: "fail",
            message: "请输入正确的身份证信息"
          });
          return;
        }
      }
      stayTime = stayTime.split("-").join("/");
      if (haveTime) {
        haveTime = haveTime.split("-").join("/");
      }
      let resp = await uploadOrderStatus({
        orderId,
        userCardNum,
        stayTime,
        haveTime
      });
      console.log("resp", resp);
      if (resp.code == 200) {
        this.$message({
          type: "success",
          message: "更改成功"
        });
        this.showMinsu = false;
        this.minsuData = {};
        this.getList(1);
      } else {
        this.$message({
          type: "fail",
          message: resp.msg
        });
      }
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/order/shop/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '订单管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      this.$confirm(`确认删除{${row.name}}订单嘛？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosTea({
          url: `/shop/specialist/del`,
          method: "get",
          params: {
            id: row.id
          }
        });
        // console.log(data, '删除返回')
        if (data.code === 200) {
          this.$message.success("删除成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
        }
      }).catch(_ => _);
    },
    // 查看详情/编辑
    edit(row = {}) {
      // console.log(row, 'kkkk看看看看')
      this.isEdit = "edit";
      this.editForm = row.orderId;
      this.orderEditShow = true;
      // this.getInfo(row)
    },

    // 修改状态
    async changeStatus(row, newVal) {
      // /order/shop/update
      console.log(newVal, "修改状态", row);
      this.$confirm(`确认修改该订单状态嘛？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosTea({
          url: `/order/shop/update`,
          method: "post",
          params: {
            orderId: row.orderId,
            orderState: newVal.value
          }
        });
        console.log(data, "修改状态返回");
        if (data.code === 200) {
          this.$message.success("修改状态成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
        }
      }).catch(_ => _);
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url = "/order/shop/update";
      let that = this;
      var dataForm;
      dataForm = {
        id: form.id,
        // 修改时的主键id
        ossPath: form.ossPath,
        // 图片
        state: form.state,
        //	状态：1-正常(新增时默认);0-冻结
        remark: form.remark,
        // 备注
        name: form.name,
        //	名称
        number: form.number,
        //	编号
        phone: form.phone,
        //	手机号
        speciality: form.speciality //	特长
      };

      this.loading = true;
      let token = this.$cookie.get("token");
      let [success, data] = await this.$axiosTea({
        url,
        method: "post",
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.orderEditShow = false;
        that.$message.success("操作成功");
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    OrderEdit
  }
};