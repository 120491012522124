/*
 * @Author: 洪周琴 7345469+hong_zhou_qin@user.noreply.gitee.com
 * @Date: 2023-08-30 09:19:49
 * @LastEditors: 洪周琴 7345469+hong_zhou_qin@user.noreply.gitee.com
 * @LastEditTime: 2023-09-14 20:42:28
 * @FilePath: \wxguanya\src\utils\global.config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 接口地址
/**
 * 得出当前环境，取值：
 * dev 开发环境
 * test 测试环境
 * pro 正式环境
 */
const API_BASE = {
  pro: {
    //公司阿里云正式服
    per: 'https://console.irobotop.com/renren',
    //后台端口登录
    wisdom: 'https://console.irobotop.com/farms',
    // 智慧农场
    cms: 'https://www.szgy-investment.com/szgy',
    // 冠亚冠亚小程序
    szrs: 'https://www.szrs-investment.com/szrs',
    // 苏州融实小程序
    tea: 'https://console.irobotop.com/teaApp',
    // 茶馆小程序
    up: "https://console.irobotop.com" // 上传资源
  },

  yunzh: {
    //云之画-茶馆预约
    per: 'https://www.yunzhihua.com.cn/renren',
    //系统基础接口
    tea: 'https://www.yunzhihua.com.cn/teaApp',
    // 茶馆小程序
    up: "https://console.irobotop.com" // 上传资源
  },

  // uat: {
  //   per: 'https://www.szgy-investment.com/renren',//后台端口登录
  //   wisdom: 'https://dialogue.irobotop.com/farms', // 智慧农场
  //   cms: 'https://www.szgy-investment.com/szgy', // 冠亚小程序
  //   tea: 'https://console.irobotop.com/teaApp', // 茶馆小程序
  //   up: "https://console.irobotop.com", // 上传资源
  // },
  // test: {
  //   per: 'https://www.szgy-investment.com/renren',//后台端口登录
  //   wisdom: 'https://dialogue.irobotop.com/farms', // 智慧农场
  //   cms: 'https://www.szgy-investment.com/szgy', // 冠亚小程序
  //   tea: 'https://console.irobotop.com/teaApp', // 茶馆小程序
  //   up: "https://console.irobotop.com", // 上传资源
  // },
  dev: {
    per: 'https://console.irobotop.com/renren',
    //后台端口登录
    wisdom: 'https://console.irobotop.com/farms',
    // 智慧农场
    cms: 'https://www.szgy-investment.com/szgy',
    // 冠亚冠亚小程序
    szrs: 'https://www.szrs-investment.com/szrs',
    // 苏州融实小程序
    tea: 'https://console.irobotop.com/teaApp',
    // 茶馆小程序
    up: "https://console.irobotop.com" // 上传资源
  }
};

const title = {
  dev: "dev",
  pro: "Ytlz",
  yunzh: "云之画"
};
console.log("ENV", ENV);
export const webTitle = title[ENV] + " | 数据管理平台";
export const ENV_CONFIG = API_BASE[ENV];
console.log("webTitle", webTitle);