import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import common from './common';
import auth from './auth';
import lib from './lib';
import getters from './getters';
Vue.use(Vuex);
export default new Vuex.Store({
  getters,
  modules: {
    common,
    auth,
    lib
  },
  mutations: {
    // 重置vuex本地储存状态
    resetStore(state) {
      Object.keys(state).forEach(key => {
        state[key] = cloneDeep(window['storeState'][key]);
      });
    }
  }
  // 无论何时发生了状态变更且不是由 mutation 函数引起的，将会抛出错误
  // 不要在发布环境下启用严格模式！严格模式会深度监测状态树来检测不合规的状态变更——请确保在发布环境下关闭严格模式，以避免性能损失
  // strict: ENV !== 'pro'
});