//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActivityEdit from "@/components/teahouseManage/ActivityEdit";

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  name: undefined,
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      activityEditShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ""].includes(this.queryInfo.name)) {
        this.queryInfo.name = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/shop/promotion/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '活动管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      let word = row.state ? "禁用" : "启用";
      this.$confirm(`确认${word}{${row.name}}活动吗？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosTea({
          url: `/shop/promotion/uptState`,
          method: "get",
          params: {
            id: row.id
          }
        });
        // console.log(data, '删除返回')
        if (data.code === 200) {
          // this.$message.success("删除成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
        }
      }).catch(_ => _);
    },
    // 新增
    addressEdit() {
      this.isEdit = "add";
      this.editForm = {};
      this.activityEditShow = true;
    },
    // 查看详情/编辑
    edit(row = {}) {
      this.isEdit = "edit";
      this.getInfo(row);
    },
    // 获取详情
    async getInfo(row = {}) {
      let that = this;
      let [success, data] = await this.$axiosTea({
        url: `/shop/promotion/get`,
        method: "get",
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        console.log(data.data, "活动详情");
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.activityEditShow = true;
    },
    // 新增或者修改的返回
    async successAdd({
      name,
      giveStandard,
      giveAmount,
      payRatioGold,
      inviteRatioGold,
      inviteParentRatioGold,
      endTime,
      id
    }) {
      let url = "/shop/promotion/save";
      let that = this;
      let dataForm = {
        name,
        giveStandard,
        giveAmount,
        payRatioGold,
        inviteRatioGold,
        inviteParentRatioGold,
        endTime
      };
      if (this.isEdit === "edit") {
        dataForm.id = id;
      }
      this.loading = true;
      let token = this.$cookie.get("token");
      let [success, data] = await this.$axiosTea({
        url,
        method: "post",
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.activityEditShow = false;
        that.$message.success("操作成功");
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    ActivityEdit
  }
};