//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MemberEdit from "@/components/teahouseManage/MemberEdit";
import Settlement from "@/components/teahouseManage/Settlement";
import MemberEquityList from "@/components/teahouseManage/MemberEquityList";
import { getcoinRecord, getpointsRecord, getCardEquity } from "./api";

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  state: undefined,
  email: undefined,
  phone: undefined,
  nickName: undefined,
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  name: "huiyuanguanli",
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      stateList,
      // 状态
      editForm: null,
      // 编辑传输的数据
      MemberEditShow: false,
      // 添加 编辑的弹框是否显示
      settlementShow: false,
      // 账单结算弹框
      isMemberEquity: false,
      //会员权益管理
      settlementForm: null,
      userId: null,
      statuValue: "金币",
      isShowDetail: false,
      cardValue: [],
      business: ""
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ""].includes(this.queryInfo.state)) {
        this.queryInfo.state = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.nickName)) {
        this.queryInfo.nickName = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.phone)) {
        this.queryInfo.phone = undefined;
      }
      if ([undefined, null, ""].includes(this.queryInfo.email)) {
        this.queryInfo.email = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/member/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '会员管理啊')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      this.$confirm(`确认删除{${row.name}}会员嘛？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosTea({
          url: `/farm/delete`,
          method: "get",
          params: {
            id: row.id
          }
        });
        if (data.code === 200) {
          this.$message.success("删除成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
        }
      }).catch(_ => _);
    },
    // 查看详情/编辑
    edit(row = {}) {
      this.getInfo(row);
    },
    // 获取详情
    async getInfo(row = {}, settlement) {
      let [success, data] = await this.$axiosTea({
        url: `/member/get`,
        method: "get",
        params: {
          userId: row.userId
        }
      });
      console.log("data", data);
      if (data.code == 200) {
        this.editForm = data.data;
        this.settlementForm = data.data;
      } else {
        this.editForm = null;
        this.settlementForm = null;
      }
      if (settlement == "settlement") {
        this.settlementShow = true;
      } else {
        this.MemberEditShow = true;
      }
    },
    // 新增或者修改的返回
    async successAdd(form) {
      var url;
      let that = this;
      url = "/member/update";
      this.loading = true;
      let token = this.$cookie.get("token");
      let [success, data] = await this.$axiosTea({
        url,
        method: "post",
        header: {
          token
        },
        params: {
          integral: form.integral
        }
      });
      this.loading = false;
      if (data.code == 200) {
        that.MemberEditShow = false;
        that.$message.success("操作成功");
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    },
    // 账单结算
    settlement(row) {
      this.business = "账单结算";
      this.settlementShow = true;
      this.settlementForm = row;
    },
    // 金币核销
    modifyGold(row) {
      this.business = "金币核销";
      this.settlementShow = true;
      this.settlementForm = row;
    },
    async equityClick(userId) {
      console.log(userId);
      this.userId = userId;
      this.isMemberEquity = true;
    },
    // 输入金额返回
    async successSettlement(form) {
      // console.log(form)
      var dataForm = {
        userId: form.userId,
        //	用户id
        integral: form.number,
        //	结算的积分
        gold: form.number,
        //	结算的金币
        remark: form.remark,
        type: form.type
      };
      let url = "";
      if (this.business == "账单结算") {
        url = `/member/consume`;
      }
      if (this.business == "金币核销") {
        url = `/member/gold/modify`;
      }
      let that = this;
      let [success, data] = await this.$axiosTea({
        url: url,
        method: "get",
        params: dataForm
      });
      if (data.code == 200) {
        this.settlementShow = false;
        that.$message.success("操作成功");
        that.getList(this.queryInfo.pageNumber);
      } else {
        that.$message.warning(data.msg);
      }
    },
    async memberEquitySuccess() {
      console.log("ok");
    },
    // 金币记录
    async coinRecordHandle(row) {
      let resp = await getcoinRecord(row.userId);
      console.log("resp", resp);
      this.statuValue = "金币";
      this.cardValue = resp.data.rows;
      this.isShowDetail = true;
    },
    // 积分记录
    async pointsRecordHandle(row) {
      let resp = await getpointsRecord(row.userId);
      this.statuValue = "积分";
      console.log("getpointsRecord", resp);
      this.cardValue = resp.data.rows;
      this.isShowDetail = true;
    }
  },
  // watch: {},
  // computed: {},
  components: {
    MemberEdit,
    Settlement,
    MemberEquityList
  }
};