//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddOrUpdate from './schedule-add-or-update';
import Log from './schedule-log';
export default {
  data() {
    return {
      dataForm: {
        beanName: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      logVisible: false
    };
  },
  components: {
    AddOrUpdate,
    Log
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      // this.$http({
      //   url: this.$http.adornUrl('/sys/schedule/list'),
      //   method: 'get',
      //   params: this.$http.adornParams({
      //     'page': this.pageIndex,
      //     'limit': this.pageSize,
      //     'beanName': this.dataForm.beanName
      //   })
      // })
      // console.log("获取数据列表");
      this.$axiosPer({
        url: '/sys/schedule/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          beanName: this.dataForm.beanName
        }
      }).then(datas => {
        // console.log("datas", datas);
        let [success, data] = datas;
        this.dataList = datas[1].page.list;
        this.dataListLoading = false;
      });
      // .then(({ data }) => {
      //   // if (data && data.status === 0) {
      //   //   this.dataList = data.page.list;
      //   //   this.totalPage = data.page.totalCount;
      //   // } else {
      //   //   this.dataList = [];
      //   //   this.totalPage = 0;
      //   // }
      //   // this.dataListLoading = false;
      // });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      // console.log("id", id);
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
        // console.log("addOrUpdate", this.$refs.addOrUpdate.init);
        // AddOrUpdate.init(id);
      });
    },

    // 删除
    deleteHandle(id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.jobId;
      });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$http({
        //   url: this.$http.adornUrl("/sys/schedule/delete"),
        //   method: "post",
        //   data: this.$http.adornData(ids, false),
        // })
        this.$axiosPer({
          url: '/sys/schedule/delete',
          method: 'post',
          data: ids
        }).then(({
          data
        }) => {
          if (data && data.status === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {});
    },
    // 暂停
    pauseHandle(id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.jobId;
      });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '暂停' : '批量暂停'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$http({
        //   url: this.$http.adornUrl("/sys/schedule/pause"),
        //   method: "post",
        //   data: this.$http.adornData(ids, false),
        // })
        this.$axiosPer({
          url: '/sys/schedule/pause',
          method: 'post',
          data: ids
        }).then(res => {
          let data = res[1];
          // console.log('pause', data, res)

          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {});
    },
    // 恢复
    resumeHandle(id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.jobId;
      });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '恢复' : '批量恢复'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$http({
        //   url: this.$http.adornUrl("/sys/schedule/resume"),
        //   method: "post",
        //   data: this.$http.adornData(ids, false),
        // })
        this.$axiosPer({
          url: '/sys/schedule/resume',
          method: 'post',
          data: ids
        }).then(res => {
          let data = res[1];
          // console.log('resume', res, data)
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {});
    },
    // 立即执行
    runHandle(id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.jobId;
      });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '立即执行' : '批量立即执行'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$http({
        //   url: this.$http.adornUrl("/sys/schedule/run"),
        //   method: "post",
        //   data: this.$http.adornData(ids, false),
        // })
        this.$axiosPer({
          url: '/sys/schedule/run',
          method: 'post',
          data: ids
        }).then(res => {
          let data = res[1];
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {});
    },
    // 日志列表
    logHandle() {
      this.logVisible = true;
      this.$nextTick(() => {
        this.$refs.log.init();
      });
    }
  }
};