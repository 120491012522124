//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SidebarSubMenu from "./SidebarSubMenu";
// import { isURL } from '@/utils/validate'
export default {
  data() {
    return {
      menuList: [],
      dynamicMenuRoutes: [],
      menuActiveName: ""
    };
  },
  components: {
    SidebarSubMenu
  },
  computed: {
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      }
    }
  },
  watch: {
    $route: "routeHandle"
  },
  created() {
    let menuList = JSON.parse(sessionStorage.getItem("menuList") || "[]");
    this.menuList = deepFilter(menuList); // 把不需要展示在菜单中的路由过滤掉
    this.dynamicMenuRoutes = JSON.parse(sessionStorage.getItem("dynamicMenuRoutes") || "[]");
    this.routeHandle(this.$route);

    // 把不需要展示在菜单中的路由过滤掉
    function deepFilter(list) {
      // console.log(list,'导航栏数据');
      let newList = list.filter(item => !item.hideInMenu);
      newList.forEach(menu => {
        if (menu.list && menu.list.length) {
          menu.list = deepFilter(menu.list);
        }
      });
      return newList;
    }
  },
  methods: {
    // 路由操作
    routeHandle(route) {
      if (route.meta.isTab) {
        this.menuActiveName = route.meta.menuId + "";
      }
    }
  }
};