//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      imgLoading: false,
      loading: false,
      tableKey: 1,
      // table表格组件的key
      dataList: []
    };
  },
  mounted() {
    console.log("权益列表");
    this.getList(this.userId);
  },
  props: ['userId'],
  methods: {
    equityUse(row) {
      console.log(row);
      this.$confirm(`确认核销一次{${row.memberEquity.name}}权益吗？`).then(async () => {
        let [success, data] = await this.$axiosTea({
          url: `/member/card/equity/use`,
          method: "get",
          params: {
            userId: row.userId,
            equityId: row.equityId
          }
        });
        if (data.code === 200) {
          this.$message.success("核销成功！");
          this.getList(this.userId);
        } else {
          this.$message.warning(data.msg);
        }
      }).catch(_ => _);
      // return
      //this.$emit('success', this.dataForm)
    },

    async getList(userId) {
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: "/member/card/equityInfo",
        method: "get",
        params: {
          userId: userId
        }
      });
      this.loading = false;
      if (data.code === 200) {
        this.dataList = data.data || [];
      } else {
        this.dataList = [];
      }
      this.tableKey++; // 更新table表格组件的key
    }
  },

  components: {}
};