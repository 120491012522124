//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheckAddress from '@/components/CheckAddress';
// // 1-正常(新增时默认);0-冻结
// const stateList = [
//   {
//     value: 0,
//     label: '冻结',
//   },
//   {
//     value: 1,
//     label: '正常',
//   },
// ]
export default {
  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (this.ruleForm.address.province == '') {
    //     callback(new Error('请选择所属地区'))
    //   }
    //   callback()
    // }
    let form = this.form || {
      userId: form.userId,
      //
      id: form.id,
      //修改时的主键id
      province: form.province,
      // 省
      area: form.area,
      //		区县
      city: form.city,
      //	城市
      detailed: form.detailed,
      // 详细地址
      isDefault: form.isDefault,
      // 	是否为默认收获地址 true 是 false 否
      phone: form.phone,
      // 手机号
      remark: form.remark,
      // 备注
      userName: form.userName,
      // 收货名称
      state: form.state //	状态：1-正常(新增时默认);0-冻结
    };

    let dataForm = form;
    return {
      dataForm,
      imgLoading: false,
      loading: false,
      supplierList: [],
      direction: 'btt',
      ifCreate: false,
      ruleForm: {
        address: {
          // 省
          province: '',
          // 市
          city: '',
          // 区
          district: ''
        }
      },
      userAllList: [],
      // 用户列表
      chooseF: null // 选择的用户
      // rules: {
      //   address: [{ required: true, validator: validatePass, trigger: 'blur' }],
      // },
    };
  },

  mounted() {
    // modify==='add'?1:dataForm.state
    // console.log(this.modify)
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
    this.getAllUserList();
  },
  props: ['form', 'modify'],
  methods: {
    async getAllUserList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/wx/mini/findList`,
        method: 'get',
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        let allUsers = data.data.rows;
        let keyArray = {};
        allUsers.forEach(item => {
          keyArray[item.id] = item.name;
        });
        this.allUser = keyArray;
        // return
        let datalist = data.data;
        this.userAllList = datalist.rows || [];
      } else {
        this.userAllList = [];
      }
    },
    save() {
      if (this.chooseF) {
        this.dataForm.userId = this.chooseF.userId;
      }
      this.dataForm.province = this.ruleForm.address.province;
      this.dataForm.area = this.ruleForm.address.city;
      this.dataForm.city = this.ruleForm.address.district;
      // console.log(this.dataForm, '这个要保存到')
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {
    CheckAddress
  }
};