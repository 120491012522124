//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddressAdd from "@/components/smartFarm/AddressAdd";

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  userId: undefined,
  // id
  pageNumber: 1,
  // 页面大小
  pageSize: 10 // 页码
};
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      addAddressShow: false,
      // 添加 编辑的弹框是否显示
      userAllList: [],
      // 用户列表
      chooseF: null,
      // 选择的用户
      allUser: {} // 用于展示的用户id和名字对应们
    };
  },

  mounted() {
    this.getAllUserList();
    this.getList(1);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ""].includes(this.queryInfo.userId)) {
        this.queryInfo.userId = undefined;
      }
      this.getList(1);
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      if (this.chooseF) {
        this.queryInfo.userId = this.chooseF.userId;
      }
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/harvest/address/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data)
        return;
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // userAllList
    // 获取所有用户
    async getAllUserList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/wx/mini/findList`,
        method: "get",
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        let allUsers = data.data.rows;
        let keyArray = {};
        allUsers.forEach(item => {
          keyArray[item.id] = item.name;
        });
        this.allUser = keyArray;
        // return
        let datalist = data.data;
        this.userAllList = datalist.rows || [];
      } else {
        this.userAllList = [];
      }
    },
    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 删除类型数据
    remove(row) {
      this.$confirm(`确认删除嘛？`).then(async () => {
        this.$set(row, "removing", true);
        let [success, data] = await this.$axiosWi({
          url: `/harvest/address/delete`,
          method: "get",
          params: {
            id: row.id
          }
        });
        if (data.code === 200) {
          this.$message.success("删除成功！");
          this.getList(1);
        } else {
          this.$set(row, "removing", false);
          // console.log("this.$set", this.$set);
        }
      }).catch(_ => _);
    },
    // 新增
    AddressAdd() {
      this.isEdit = "add";
      this.editForm = {};
      this.addAddressShow = true;
    },
    // 查看详情/编辑
    async edit(row = {}) {
      this.isEdit = "edit";
      let that = this;
      let [success, data] = await this.$axiosWi({
        url: `/harvest/address/get`,
        method: "get",
        params: {
          id: row.id
        }
      });
      if (data.code == 200) {
        // console.log(data.data, '农产品详情')
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.addAddressShow = true;
    },
    // 新增或者修改的返回
    async successAdd(form) {
      // console.log(form)
      // return
      var url;
      let that = this;
      var dataForm;
      if (this.isEdit === "add") {
        dataForm = {
          userId: form.userId,
          //
          province: form.province,
          // 省
          area: form.area,
          //		区县
          city: form.city,
          //	城市
          detailed: form.detailed,
          // 详细地址
          isDefault: form.isDefault,
          // 	是否为默认收获地址 true 是 false 否
          phone: form.phone,
          // 手机号
          remark: form.remark,
          // 备注
          userName: form.userName,
          // 收货名称
          state: form.state //	状态：1-正常(新增时默认);0-冻结
        };
      } else if (this.isEdit === "edit") {
        dataForm = {
          userId: form.userId,
          //
          id: form.id,
          //修改时的主键id
          province: form.province,
          // 省
          area: form.area,
          //		区县
          city: form.city,
          //	城市
          detailed: form.detailed,
          // 详细地址
          isDefault: form.isDefault,
          // 	是否为默认收获地址 true 是 false 否
          phone: form.phone,
          // 手机号
          remark: form.remark,
          // 备注
          userName: form.userName,
          // 收货名称
          state: form.state //	状态：1-正常(新增时默认);0-冻结
        };
      }

      this.loading = true;
      let token = this.$cookie.get("token");
      let [success, data] = await this.$axiosWi({
        url: "/harvest/address/save",
        method: "post",
        header: {
          token
        },
        params: dataForm
      });
      this.loading = false;
      if (data.code == 200) {
        that.addAddressShow = false;
        that.$message.success("操作成功");
        that.getList(1);
      } else {
        that.$message.warning(data.msg);
      }
    }
  },
  // watch: {},
  // computed: {},
  components: {
    AddressAdd
  }
};