//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "UploadExcel",
  data() {
    return {
      thisLoading: false,
      acceptArr: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"],
      progress: 0 // 进度, 0 ~ 1
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      required: true,
      type: String
    },
    type: {
      type: String,
      default: "primary"
    },
    apiUrl: {
      required: true,
      type: String
    },
    clazzId: {
      required: true,
      type: Number
    }
  },
  methods: {
    // 上传前配置限制
    beforeUpload(file) {
      let fileType = file.type;
      if (!this.acceptArr.includes(fileType)) {
        this.$message.warning("请上传「xls」或「xlsx」的 Excel 格式文件！");
        return false;
      }
      return true;
    },
    // 上传功能覆盖
    async httpRequest(event) {
      console.log("httpRequest=", this.apiUrl);
      let form = new FormData();
      // form.append(event.filename, event.file);
      form.append("file", event.file);
      form.append("clazzId", this.clazzId);
      this.progress = 0;
      this.thisLoading = true;
      let result = await this.$axiosCms({
        url: this.apiUrl,
        method: "post",
        headers: {
          "content-type": "multipart/form-data"
        },
        onUploadProgress: this.setProgress,
        data: form
      });
      this.thisLoading = false;
      if (result[0]) {
        this.$message.success("导入成功！");
        this.$emit("success", result);
      }
    },
    // 文件上传时的钩子
    setProgress(event) {
      this.progress = event.loaded / event.total;
    }
  }
};