import { ENV_CONFIG } from '@/utils/global.config';
import Cookies from 'js-cookie';
import merge from 'lodash/merge';
let axios = require("axios");
axios.interceptors.request.use(config => {
  if (config.method.toUpperCase() === 'GET') {
    config.params = merge({
      t: Date.now()
    }, config.params);
  }
  config.headers['token'] = Cookies.get('token');
  return config;
}, error => {
  return Promise.reject(error);
});
let qs = require("qs");
async function getAxios({
  method = "get",
  url,
  headers,
  data
}) {
  let value = await axios({
    method,
    url: ENV_CONFIG.tea + url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...headers
    },
    data
  });
  return value.data;
}
// 上传商品
// export async function uploadCommodity(form) {
//     let formData = new FormData();
//     formData.append("type", form.type);
//     formData.append("state", form.state);
//     formData.append("name", form.name);
//     formData.append("inventory", form.inventory);
//     formData.append("goldPrice", form.goldPrice);
//     formData.append("file", form.file.raw);
//     if (form.id) {
//         formData.append("id", form.id);
//     }

//     let config = {
//         method: 'post',
//         url: `${ENV_CONFIG.tea}/shop/product/save`,
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//         },
//         data: formData
//     };
//     try {
//         let data = await axios(config);
//         console.log("getSubjectId", data)
//         return data.data
//     } catch (error) {
//         console.log(error)
//     }

// }

// 上传商品
export async function uploadCommodity(form) {
  let formData = new FormData();
  formData.append("type", form.type);
  formData.append("state", form.state);
  formData.append("name", form.name);
  formData.append("inventory", form.inventory);
  formData.append("goldPrice", form.goldPrice);
  if (form.file) {
    formData.append("file", form.file.raw);
  }
  if (form.id) {
    formData.append("id", form.id);
  }
  try {
    return await getAxios({
      method: "post",
      url: "/shop/product/save",
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}

// 获取商品详情
export async function getCommodityDetail(id) {
  try {
    return await getAxios({
      url: `/shop/product/get?id=${id}`
    });
  } catch (error) {
    console.log(error);
  }
}

// 上传商品详情图
export async function uploadCommodityDetail({
  sourceId = "",
  tag = "",
  fileArr,
  sourceFlag
}) {
  let formData = new FormData();
  formData.append("sourceId", sourceId);
  formData.append("tag", tag);
  if (![undefined, null, ''].includes(sourceFlag)) {
    formData.append("sourceFlag", sourceFlag);
  }
  fileArr.forEach(element => {
    formData.append("file", element);
  });
  try {
    return await getAxios({
      method: "post",
      url: "/shop/upload",
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}
// 删除商品详情图
export async function deleteDetailItem(fileId) {
  try {
    return await getAxios({
      url: `/shop/file/del?fileId=${fileId}`
    });
  } catch (error) {
    console.log(error);
  }
}

// 上传茶艺师资料
export async function uploadTeaMan(params) {
  console.log(params);
  let formData = new FormData();
  for (let key in params) {
    if (params[key]) {
      formData.set(key, params[key]);
    }
  }

  //    formData.append("grade", params.grade);
  //    formData.append("phone", params.phone);
  //    formData.append("name", params.name);
  //    formData.append("speciality", params.speciality);
  // formData.append("orderNum", params.orderNum);
  if (params.file) {
    formData.append("file", params.file.raw);
  }
  if (params.id) {
    formData.append("id", params.id);
  }
  try {
    return await getAxios({
      method: "post",
      url: "/shop/specialist/save",
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}
// export async function uploadTeaMan({ name, number, phone, speciality, file, id }) {
//     let formData = new FormData();
//     formData.append("number", number);
//     formData.append("phone", phone);
//     formData.append("name", name);
//     formData.append("speciality", speciality);
//     if (file) {
//         formData.append("file", file.raw);
//     }
//     if (id) {
//         formData.append("id", id);
//     }
//     try {
//         return await getAxios({
//             method: "post",
//             url: "/shop/specialist/save",
//             data: formData
//         });
//     } catch (error) {
//         console.log(error)
//     }
// }

// 获取权益
export async function getRightsHandle() {
  try {
    return await getAxios({
      url: "/member/equity/findList"
    });
  } catch (error) {
    console.log(error);
  }
}
// 新增权益
export async function addRightsHandle({
  name,
  description
}) {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  try {
    return await getAxios({
      method: "POST",
      url: "/member/equity/save",
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}

// 删除权益
export async function deleteRights(id) {
  try {
    return await getAxios({
      method: "GET",
      url: `/member/equity/del?id=${id}`
    });
  } catch (error) {
    console.log(error);
  }
}

// 获取会员卡列表
export async function getCardList() {
  try {
    return await getAxios({
      url: "/member/card/findList"
    });
  } catch (error) {
    console.log(error);
  }
}
export async function getCardEquity(userId) {
  try {
    return await getAxios({
      url: "/member/card/equityInfo?userId=" + userId
    });
  } catch (error) {
    console.log(error);
  }
}

// 保存会员卡信息
export async function postCardListMsg({
  id,
  integral,
  name,
  equityIds
}) {
  let formData = new FormData();
  formData.append("id", id);
  formData.append("integral", integral);
  formData.append("name", name);
  formData.append("equityIds", equityIds);
  try {
    return await getAxios({
      method: "POST",
      url: "/member/card/save",
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}

// 获取金币记录
export async function getcoinRecord(userId) {
  try {
    return await getAxios({
      url: `/member/gold/record?pageSize=20&userId=${userId}`
    });
  } catch (error) {
    console.log(error);
  }
}
// 获取积分记录
export async function getpointsRecord(userId) {
  try {
    return await getAxios({
      url: `/member/money/record?pageSize=20&userId=${userId}`
    });
  } catch (error) {
    console.log(error);
  }
}

//获取图片列表
export async function getImageList(tag) {
  try {
    return await getAxios({
      url: `/shop/fileList?tag=${tag}`
    });
  } catch (error) {
    console.log(error);
  }
}
export async function getImageList2({
  tag,
  sourceId
}) {
  try {
    return await getAxios({
      url: `/shop/fileList?tag=${tag}&&sourceId=${sourceId}`
    });
  } catch (error) {
    console.log(error);
  }
}

// 删除图片
export async function deleteImage(fileId) {
  try {
    return await getAxios({
      url: `/shop/file/del?fileId=${fileId}`
    });
  } catch (error) {
    console.log(error);
  }
}
// 上传图片
export async function uploadImage({
  tag,
  file
}) {
  let formData = new FormData();
  formData.append("tag", tag);
  file.forEach(item => {
    formData.append("file", item.raw);
  });
  try {
    return await getAxios({
      method: "POST",
      url: `/shop/upload`,
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}

// 更新订单状态
// export async function uploadOrderStatus({ orderId, orderState, deliveryNumber = "", deliveryTime = "" }) {
//     let formData = new FormData();
//     formData.append("orderId", orderId);
//     formData.append("orderState", orderState);
//     formData.append("deliveryNumber", deliveryNumber);
//     formData.append("deliveryTime", deliveryTime);
//     // formData.append("haveTime", haveTime);
//     // formData.append("stayTime", stayTime);
//     // formData.append("userCardNum", userCardNum);

//     try {
//         return await getAxios({
//             method: "POST",
//             url: '/order/shop/update',
//             data: formData
//         });
//     } catch (error) {
//         console.log(error)
//     }

// }

export async function uploadOrderStatus(data) {
  let formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  try {
    return await getAxios({
      method: "POST",
      url: '/order/shop/update',
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}

// 获取预约位置
export async function getPositionHandle() {
  try {
    return await getAxios({
      url: "/member/reserve/position/list"
    });
  } catch (error) {
    console.log(error);
  }
}
// 保存预约位置
export async function savePositionHandle({
  id,
  position,
  price,
  sort
}) {
  let formData = new FormData();
  if (![undefined, null, ''].includes(id)) {
    formData.append("id", id);
  }
  formData.append("position", position);
  formData.append("price", price);
  if (![undefined, null, ''].includes(sort)) {
    formData.append("sort", sort);
  }
  try {
    return await getAxios({
      method: "POST",
      url: "/member/reserve/position/save",
      data: formData
    });
  } catch (error) {
    console.log(error);
  }
}

// 删除预约位置
export async function deletePosition(id) {
  try {
    return await getAxios({
      method: "GET",
      url: `/member/reserve/position/del?id=${id}`
    });
  } catch (error) {
    console.log(error);
  }
}