import { render, staticRenderFns } from "./msgTrends.vue?vue&type=template&id=792e6cdb&scoped=true&"
import script from "./msgTrends.vue?vue&type=script&lang=js&"
export * from "./msgTrends.vue?vue&type=script&lang=js&"
import style0 from "./msgTrends.vue?vue&type=style&index=0&id=792e6cdb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792e6cdb",
  null
  
)

export default component.exports