//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { treeDataTranslate } from '@/utils';
import Icon from '@/icons';
export default {
  data() {
    var validateUrl = (rule, value, callback) => {
      if (this.dataForm.type === 1 && !/\S/.test(value)) {
        callback(new Error('菜单URL不能为空'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        id: 0,
        type: 1,
        typeList: ['目录', '菜单', '按钮'],
        name: '',
        parentId: 0,
        parentName: '',
        url: '',
        perms: '',
        orderNum: 0,
        icon: '',
        iconList: []
      },
      dataRule: {
        name: [{
          required: true,
          message: '菜单名称不能为空',
          trigger: 'blur'
        }],
        parentName: [{
          required: true,
          message: '上级菜单不能为空',
          trigger: 'change'
        }],
        url: [{
          validator: validateUrl,
          trigger: 'blur'
        }]
      },
      menuList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children'
      }
    };
  },
  created() {
    this.iconList = Icon.getNameList();
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.$axiosPer({
        url: '/sys/menu/select',
        method: 'get'
        // params: this.$http.adornParams()
      }).then(datas => {
        let [success, data] = datas;
        this.menuList = treeDataTranslate(data.menuList, 'menuId');
      }).then(() => {
        this.visible = true;
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields();
        });
      }).then(() => {
        if (!this.dataForm.id) {
          // 新增
          this.menuListTreeSetCurrentNode();
        } else {
          // 修改
          this.$axiosPer({
            url: `/sys/menu/info/${this.dataForm.id}`,
            method: 'get'
            // params: this.$http.adornParams()
          }).then(datas => {
            let [success, data] = datas;
            this.dataForm.id = data.menu.menuId;
            this.dataForm.type = data.menu.type;
            this.dataForm.name = data.menu.name;
            this.dataForm.parentId = data.menu.parentId;
            this.dataForm.url = data.menu.url;
            this.dataForm.perms = data.menu.perms;
            this.dataForm.orderNum = data.menu.orderNum;
            this.dataForm.icon = data.menu.icon;
            this.menuListTreeSetCurrentNode();
          });
        }
      });
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data, node) {
      this.dataForm.parentId = data.menuId;
      this.dataForm.parentName = data.name;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      this.$refs.menuListTree.setCurrentKey(this.dataForm.parentId);
      this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() || {})['name'];
    },
    // 图标选中
    iconActiveHandle(iconName) {
      this.dataForm.icon = iconName;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axiosPer({
            url: `/sys/menu/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              'menuId': this.dataForm.id || undefined,
              'type': this.dataForm.type,
              'name': this.dataForm.name,
              'parentId': this.dataForm.parentId,
              'url': this.dataForm.url,
              'perms': this.dataForm.perms,
              'orderNum': this.dataForm.orderNum,
              'icon': this.dataForm.icon
            }
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};