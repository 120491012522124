//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { treeDataTranslate } from '@/utils';
export default {
  data() {
    return {
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children'
      },
      dataForm: {
        id: 0,
        roleName: '',
        remark: ''
      },
      dataRule: {
        roleName: [{
          required: true,
          message: '角色名称不能为空',
          trigger: 'blur'
        }]
      },
      tempKey: -666666 // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
    };
  },

  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.$axiosPer({
        url: '/sys/menu/list',
        method: 'get'
      }).then(datas => {
        let [success, data] = datas;
        this.menuList = treeDataTranslate(data, 'menuId');
      }).then(() => {
        this.visible = true;
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields();
          this.$refs.menuListTree.setCheckedKeys([]);
        });
      }).then(() => {
        if (this.dataForm.id) {
          this.$axiosPer({
            url: `/sys/role/info/${this.dataForm.id}`,
            method: 'get'
            // params: this.$http.adornParams()
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.dataForm.roleName = data.role.roleName;
              this.dataForm.remark = data.role.remark;
              var idx = data.role.menuIdList.indexOf(this.tempKey);
              if (idx !== -1) {
                data.role.menuIdList.splice(idx, data.role.menuIdList.length - idx);
              }
              this.$refs.menuListTree.setCheckedKeys(data.role.menuIdList);
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axiosPer({
            url: `/sys/role/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              'roleId': this.dataForm.id || undefined,
              'roleName': this.dataForm.roleName,
              'remark': this.dataForm.remark,
              'menuIdList': [].concat(this.$refs.menuListTree.getCheckedKeys(), [this.tempKey], this.$refs.menuListTree.getHalfCheckedKeys())
            }
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};