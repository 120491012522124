//
//
//
//
//
//

import CanHaves from '@/components/smartFarm/CanHaves';
export default {
  data() {
    return {
      farmShow: 'haves'
    };
  },
  mounted() {},
  methods: {},
  components: {
    CanHaves
  }
};