export default {
  namespaced: true,
  state: {
    diffProList: JSON.parse(window.localStorage.getItem('diffProList') || '[]') // 产品对比列表
  },

  mutations: {
    setDiffProList(state, data = []) {
      if (data.length) {
        window.localStorage.setItem('diffProList', JSON.stringify(data));
      } else window.localStorage.removeItem('diffProList');
      state.diffProList = data;
    }
  },
  actions: {
    addDiffProList({
      commit
    }, data) {
      if (!data) return;
      let diffProList = JSON.parse(window.localStorage.getItem('diffProList') || '[]');

      // 这个还没有加入对比框
      if (!diffProList.some(item => item.id === data.id)) {
        diffProList.push({
          id: data.id,
          title: `${data.brandName}${data.modelName}`,
          skuJc: data.skuJc
        });
      }
      commit('setDiffProList', diffProList);
    },
    removeDiffProList({
      commit
    }, row = {}) {
      let id = row.id;
      let diffProList = JSON.parse(window.localStorage.getItem('diffProList') || '[]');
      diffProList = diffProList.filter(item => item.id !== id);
      commit('setDiffProList', diffProList);
    }
  }
};