//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const typeList = [{
  value: 3,
  label: '收获物'
}, {
  value: 2,
  label: '家禽'
}, {
  value: 1,
  label: '蔬菜'
}];
// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: '冻结'
}, {
  value: 1,
  label: '正常'
}];
export default {
  data() {
    let form = this.form || {
      id: form.id,
      // 修改时的主键id
      type: form.type,
      //1-蔬菜、2-家禽、3-收获物(如鸡蛋)
      state: form.state,
      //	状态：1-正常(新增时默认);0-冻结
      name: form.name,
      //	名称
      remark: form.remark,
      coverImg: form.coverImg // 封面图片地址
      // type: form.type, // 动态类型：1-个人 0-官方
    };

    let dataForm = form;
    return {
      dataForm,
      typeList,
      // 类型list
      stateList,
      // 状态
      imgLoading: false,
      loading: false
    };
  },
  mounted() {
    // modify==='add'?1:dataForm.state
    // console.log(this.modify)
    if (this.modify === 'add') {
      this.dataForm.state = 1;
    }
  },
  props: ['form', 'modify'],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.coverImg = result.ossUrl;
      }
    },
    save() {
      // console.log(this.dataForm);
      // return
      this.$emit('success', this.dataForm);
    }
  },
  components: {}
};