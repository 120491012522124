//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrderAdd from '@/components/smartFarm/OrderAdd';

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  deliveryNumber: undefined,
  // 订单号
  orderState: undefined,
  // 订单状态 0-未配送 1-配送中 7-配送完成 9-已取消
  phone: undefined,
  // 手机号
  userName: undefined,
  // 收货人姓名
  farmId: undefined,
  // 农场id
  pageNumber: 1,
  // 页面大小
  pageSize: 10 // 页码
};

// 订单状态
const stateList = [{
  value: 0,
  label: '未配送'
}, {
  value: 1,
  label: '配送中'
}, {
  value: 7,
  label: '配送完成'
}, {
  value: 9,
  label: '已取消'
}];
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      farmList: [],
      // 农场列表
      chooseF: null,
      // 选择的农场
      allFram: {},
      // 用于展示的农场id和名字对应们

      stateList,
      // 状态
      isEdit: null,
      // 判断新增或者编辑的字段 add=新增 edit=编辑
      editForm: null,
      // 编辑传输的数据
      orderShow: false // 添加 编辑的弹框是否显示
    };
  },

  mounted() {
    this.getList(1);
    this.getFarmList();
  },
  methods: {
    // /farm/order/cancel //取消订单
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      if ([undefined, null, ''].includes(this.queryInfo.orderState)) {
        this.queryInfo.orderState = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.deliveryNumber)) {
        this.queryInfo.deliveryNumber = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.phone)) {
        this.queryInfo.phone = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.userName)) {
        this.queryInfo.userName = undefined;
      }
      if ([undefined, null, ''].includes(this.queryInfo.farmId)) {
        this.queryInfo.farmId = undefined;
      }
      this.getList(1);
    },
    // 全部农场
    async getFarmList() {
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/findList`,
        method: 'get',
        params: {
          pageSize: 999 // 页面大小
        }
      });

      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log('获取到的农场数据', data.data);
        let allFrams = data.data.rows;
        let keyArray = {};
        allFrams.forEach(item => {
          keyArray[item.id] = item.name;
        });
        this.allFram = keyArray;
        // return
        let datalist = data.data;
        this.farmList = datalist.rows || [];
      } else {
        this.farmList = [];
      }
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      if (this.chooseF) {
        this.queryInfo.farmId = this.chooseF.id;
      }
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosWi({
        url: `/farm/order/findList`,
        method: 'get',
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '订单管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    },
    // 取消订单
    cancelOrder(row) {
      this.$confirm(`确认取消该订单嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosWi({
          url: `/farm/order/cancel`,
          method: 'post',
          params: {
            orderId: row.orderId
          }
        });
        if (data.code === 200) {
          this.$message.success('取消成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    },
    // 查看详情
    async edit(row = {}) {
      this.isEdit = 'edit';
      let that = this;
      let [success, data] = await this.$axiosWi({
        url: `/farm/order/get`,
        method: 'get',
        params: {
          orderId: row.orderId
        }
      });
      if (data.code == 200) {
        // console.log(data.data, '订单详情')
        that.editForm = data.data;
      } else {
        that.editForm = null;
      }
      this.orderShow = true;
    },
    // 修改订单状态
    changeorderState(row) {
      this.$confirm(`确认修改该订单状态嘛？`).then(async () => {
        this.$set(row, 'removing', true);
        let [success, data] = await this.$axiosWi({
          url: `/farm/order/update`,
          method: 'post',
          params: {
            orderId: row.orderId,
            orderState: row.orderState,
            deliveryNumber: row.deliveryNumber
          }
        });
        if (data.code === 200) {
          this.$message.success('修改成功！');
          this.getList(1);
        } else {
          this.$set(row, 'removing', false);
        }
      }).catch(_ => _);
    }
  },
  // watch: {},
  // computed: {},
  components: {
    OrderAdd
  }
};