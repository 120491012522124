//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SidebarSubMenu from "./SidebarSubMenu";
export default {
  name: "SidebarSubMenu",
  props: {
    menu: {
      type: Object,
      required: true
    },
    dynamicMenuRoutes: {
      type: Array,
      required: true
    }
  },
  components: {
    SidebarSubMenu
  },
  methods: {
    // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
    gotoRouteHandle(menu) {
      // 配置搜索页需要更新
      // this.$store.commit('lib/setLibSearchNeedInit', true)
      let route = this.dynamicMenuRoutes.find(item => item.meta.menuId === menu.menuId);
      if (route) {
        this.$router.push({
          name: route.name
        });
      }
    }
  }
};