/*
 * @Author: ytlz-qd 1801977412@qq.com
 * @Date: 2023-03-01 13:45:50
 * @LastEditors: ytlz-qd 1801977412@qq.com
 * @LastEditTime: 2023-03-01 14:44:48
 * @FilePath: \wxguanya\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import App from '@/App';
import router from '@/router'; // api: https://github.com/vuejs/vue-router
import store from '@/store'; // api: https://github.com/vuejs/vuex
import Cookies from 'js-cookie'; // api: https://github.com/js-cookie/js-cookie#readme
import '@/element-ui'; // api: https://github.com/ElemeFE/element
import '@/icons'; // api: http://www.iconfont.cn/
import '@/assets/scss/index.scss';
import axios from '@/utils/axios'; // api: https://github.com/axios/axios
import axiosPer from '@/utils/axiosPer'; // api: https://github.com/axios/axios
import axiosCms from '@/utils/axiosCms';
import axiosSzrs from '@/utils/axiosSzrs';
import axiosWi from '@/utils/axiosWi';
import axiosTea from '@/utils/axiosTea';
import http from '@/utils/httpRequest';
import axiosUpFile from '@/utils/axiosUpFile'; // // 上传系统安装包，后续所有文件资料都可以使用该方法
import { isAuth, getDate, list2map } from '@/utils';
import cloneDeep from 'lodash/cloneDeep';
import '@/utils/globalComponents.js'; // 引入全局引入的组件
import 'video.js/dist/video-js.min.css';
// 隐藏 右侧浏览器 滚动条
// import VueSmoothScroll from "vue-smooth-scroll";
// Vue.use(VueSmoothScroll)

Vue.config.productionTip = false;
// 挂载全局
Vue.prototype.$axios = axios; // 业务功能的接口
Vue.prototype.$axiosPer = axiosPer; // 登录、权限系统的接口
Vue.prototype.$axiosCms = axiosCms; // 登录、权限系统的接口
Vue.prototype.$axiosSzrs = axiosSzrs; // 苏州融实接口
Vue.prototype.$axiosWi = axiosWi; // 登录、权限系统的接口
Vue.prototype.$axiosTea = axiosTea; // 茶馆接口
Vue.prototype.$axiosUpFile = axiosUpFile; // 上传系统安装包，后续所有文件资料都可以使用该方法
Vue.prototype.isAuth = isAuth; // 权限方法
Vue.prototype.$cookie = Cookies; // cookie 方法
Vue.prototype.getDate = getDate; // 时间戳转为年月日时分秒
Vue.prototype.cloneDeep = cloneDeep; // 深度克隆方法
Vue.prototype.list2map = list2map; // select 使用的 list，转为 value -> label 的 map
Vue.prototype.$http = http;

// 保存整站vuex本地储存初始状态
window['storeState'] = cloneDeep(store.state);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
});