//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  data() {
    let form = this.form || {
      id: form.id,
      // 修改时的主键id
      type: form.type,
      // 商品类型-手填吧
      state: form.state,
      //	状态：1-正常(新增时默认);0-冻结
      name: form.name,
      //	名称
      inventory: form.inventory,
      // 备注
      goldPrice: form.goldPrice,
      // 金币价格
      ossPath: form.ossPath // 资源存储路径
    };

    let dataForm = form;
    return {
      dataForm,
      stateList,
      // 状态
      imgLoading: false,
      loading: false,
      imgLoading: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: []
    };
  },
  mounted() {
    // modify==='add'?1:dataForm.state
    if (this.modify === "add") {
      this.dataForm.state = 1;
    } else {
      this.fileList = [{
        url: this.dataForm.fileUrl
      }];
    }
  },
  props: ["form", "modify"],
  methods: {
    // 图片上传完成
    // upLoadData(result) {
    //   this.imgLoading = false;
    //   if (result) {
    //     this.dataForm.ossPath = result.ossUrl;
    //   }
    // },
    changeFileHandle(file, fileList) {
      this.dataForm.file = file;
      this.fileList = [file];
      console.log(file, fileList, this.fileList, this.dataForm);
    },
    save() {
      console.log("是点这里报错吗？");
      console.log(this.dataForm);
      // return
      this.$emit("success", this.dataForm);
    },
    handleRemove(file) {
      this.fileList = [];
      this.dataForm.file = null;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    }
  },
  components: {}
};