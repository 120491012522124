//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1-正常(新增时默认);0-冻结
const stateList = [{
  value: 0,
  label: "冻结"
}, {
  value: 1,
  label: "正常"
}];
export default {
  name: "EditActive",
  data() {
    // let form = this.form || {
    //   id: form.id, // 修改时的主键id
    //   ossPath: form.ossPath, // 活动类型-手填吧
    //   state: form.state, //	状态：1-正常(新增时默认);0-冻结
    //   name: form.name, //	名称
    //   remark: form.remark, // 备注
    // };
    // let dataForm = form;
    return {
      dataForm: this.form,
      stateList,
      // 状态
      imgLoading: false,
      loading: false,
      value2: this.form.endTime,
      disabled: false
    };
  },
  created() {
    // modify==='add'?1:dataForm.state
    if (this.modify === "add") {
      this.dataForm.state = 1;
      this.dataForm.name = "限时首充送积分";
      this.form.name = "限时首充送积分";
    } else {
      // console.log("endTime", this.dataForm.endTime.split(" ")[0].split("-").join("/"));
      this.dataForm.endTime = this.dataForm.endTime.split(" ")[0].split("-").join("/");
      this.disabled = true;
    }
  },
  props: ["form", "modify"],
  methods: {
    // 图片上传完成
    upLoadData(result) {
      this.imgLoading = false;
      if (result) {
        this.dataForm.ossPath = result.ossUrl;
      }
    },
    save() {
      // console.log(this.dataForm)
      // return
      this.$emit("success", this.dataForm);
    },
    changeDate(value) {
      this.dataForm.endTime = value.split("-").join("/");
      console.log("value", value, this.dataForm.endTime);
    }
  },
  components: {}
};