//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Upload',
  data() {
    return {
      thisLoading: false,
      progress: 0 // 进度, 0 ~ 1
    };
  },

  props: {
    drag: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    beforeUpload: {
      type: Function,
      default: undefined
    },
    httpRequest: {
      type: Function,
      default: undefined
    },
    httpProgress: {
      type: Number,
      // 进度 0~1，只有传了 httpRequest，这个字段才会生效
      default: 0
    },
    // 是否尺寸100%
    autoFill: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // 定义的接口地址类型
    apiType: String,
    // 上传的文件类型
    fileStyle: {
      type: Number,
      // 0 是图片，1是音频，2是视频
      default: 0
    }
  },
  methods: {
    // 上传前配置限制
    thisBeforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15;
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 15MB!');
      }
      return isLt10M;
    },
    // 上传功能覆盖
    async uploadApi(event) {
      this.progress = 0;
      this.thisLoading = true;
      this.$emit('changeBefore');
      let form = new FormData();
      form.append('file', event.file);
      let [success, data] = await this.$axiosUpFile({
        url: '/apis/upload-service/upload/file',
        method: 'post',
        headers: {
          'content-type': 'multipart/form-data'
        },
        data: form,
        onUploadProgress: this.setProgress
      });
      this.thisLoading = false;
      this.progress = 0;
      this.$emit('change', data);
    },
    // 文件上传时的钩子
    setProgress(event) {
      this.progress = event.loaded / event.total;
    }
  },
  computed: {
    progressUse() {
      if (this.httpRequest) return this.httpProgress;else return this.progress;
    }
  }
};