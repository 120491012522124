//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      dataRule: {}
    };
  },
  methods: {
    init(id) {
      this.visible = true;
      this.$axiosPer({
        url: '/sys/oss/config',
        method: 'get'
        // params: this.$http.adornParams()
      }).then(datas => {
        let [success, data] = datas;
        this.dataForm = data && data.code === 0 ? data.config : [];
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axiosPer({
            url: '/sys/oss/saveConfig',
            method: 'post',
            data: this.dataForm
          }).then(datas => {
            let [success, data] = datas;
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};