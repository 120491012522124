//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 用户可能修改了筛选器，但没有点击查询，而是直接又去翻页了，此对象用户展示筛选器
const QUERY_INFO_DEFAULT = {
  pageNumber: 1,
  // 页码
  pageSize: 10 // 页面大小
};

export default {
  name: "yuyue",
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      tableKey: 1,
      // table表格组件的key
      queryInfoTemp: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 临时请求对象
      queryInfo: this.cloneDeep(QUERY_INFO_DEFAULT),
      // 请求对象
      response: null
    };
  },
  mounted() {
    this.getList(1);
    console.log(111);
  },
  methods: {
    // 搜索
    search() {
      this.queryInfo = this.cloneDeep(this.queryInfoTemp);
      this.getList(1);
    },
    //是否多服务数据处理
    userName(row, column) {
      if (row.userToken) {
        let user = JSON.parse(row.userToken);
        console.log(user);
        return user.username;
      } else {
        return "-";
      }
    },
    resultMsg(row, column) {
      if (row.userToken) {
        let result = JSON.parse(row.result);
        console.log(result);
        return result.msg;
      } else {
        return "-";
      }
    },
    // 获取列表
    async getList(pageNumber = this.queryInfo.pageNumber || 1) {
      this.queryInfo.pageNumber = pageNumber;
      let queryInfo = this.cloneDeep(this.queryInfo);
      this.loading = true;
      let [success, data] = await this.$axiosTea({
        url: `/sys/log/findList`,
        method: "get",
        params: queryInfo
      });
      this.loading = false;
      // return
      if (data.code === 200) {
        // console.log(data.data.rows, '预约管理')
        // return
        let datalist = data.data;
        this.list = datalist.rows || [];
        this.total = datalist.total || 0;
      } else {
        this.list = [];
      }
      this.tableKey++; // 更新table表格组件的key
    },

    // 修改每页条数 pageSize
    changepageSize(newVal) {
      this.queryInfoTemp.pageSize = newVal;
      this.queryInfo.pageSize = newVal;
      this.getList();
    }
  },
  // watch: {},
  // computed: {},
  components: {}
};